import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import bg from "./bg/signin.svg";
import bgimg from "./bg/backimg.jpg";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import React, { useState, forwardRef, useEffect } from "react";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import MuiAlert from "@mui/material/Alert";
import Slide from "@mui/material/Slide";
import { useNavigate } from "react-router-dom";
import axios from "axios"
import configration from "../config/config";
import { ToastContainer, toast } from 'react-toastify';
import isEmpty from "is-empty";
import { FaEye } from "react-icons/fa";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { jwtDecode } from "jwt-decode";
import { getdetailsadmin } from "./api/authapis";
import CryptoJS from "crypto-js";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});



const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

const boxstyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "75%",
  height: "70%",
  bgcolor: "background.paper",
  boxShadow: 24,
};

const center = {
  position: "relative",
  top: "50%",
  left: "37%",
};

export default function Login() {
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("");
  const [error, setError] = useState({});
  const [isloader, setIsloader] = useState(false)
  const vertical = "top";
  const horizontal = "right";
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = React.useState(false);
  const toastCheck = React.useRef(null);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = async (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    // let adminToken = localStorage.getItem('token');
    // console.log("adminToken", adminToken);
    // if (adminToken != null ) {
    //   window.location.href = '/dashboard';

    // }
    CheckTokenIslogin();
  }, [])

  const CheckTokenIslogin = async () => {
    try {
      const reponse = await getdetailsadmin();



      const value = JSON.parse(JSON.stringify(reponse.data.data));
      const idVal = value[0]._id


      if (!idVal) {
        if (!toast.isActive(toastCheck.current)) {
          if (toastCheck.current === null) {
            toastCheck.current = toast.warning("Data Not Found");
            console.log("toastCheck", toastCheck.current);
          }
        }
      }
      const admintoken = localStorage.getItem("token")
      const decoded = jwtDecode(admintoken);


      let decoded_id = decoded.userid


      if (idVal === decoded_id) {
        window.location.href = "/dashboard";
      }

    } catch (error) {
      // console.log("error", error);

      if (error.name === "InvalidTokenError") {
        console.log("InvalidTokenError");
      }
    }
  }

  const handleSubmit = async (event) => {
    setOpen(true);
    event.preventDefault();
    new FormData(event.currentTarget);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  function TransitionLeft(props) {
    return <Slide {...props} direction="left" />;
  }

  async function handleLoginSubmit(event) {
    event.preventDefault();
    try {
      const Validateerror = {};

      if (isEmpty(email)) {
        Validateerror.email = "email is required"
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
        Validateerror.email = "Invalid email address"
      }

      if (isEmpty(password)) {
        Validateerror.password = "password is required"
      } else if (password.length < 4) {
        Validateerror.password = "Password must be at least 5 characters"
      }

      setError(Validateerror)

      if (Object.keys(Validateerror).length === 0) {
        setIsloader(true)
        const passwordhash = CryptoJS.AES.encrypt(password, "pass123").toString()
        const response = await axios.post(`${configration.localhostBackend}login`, { email, passwordhash });

        const { token, message } = response.data;

        if (token) {
          localStorage.setItem('token', token);
          toast.success('Login successful...');
          setTimeout(() => {
            navigate("/dashboard")
          }, 1000);
        } else if (message == "User Not Found") {
          // alert('Please check your username and password');
          setIsloader(false)
          toast.warn('Please check your username');
        } else {
          setIsloader(false)
          toast.warn("Please check your password");
        }
      }

    } catch (error) {
      toast.error("Login Failed...");
      setIsloader(false)
    }
  }

  return (
    <div className="login-page">
      {/* <ToastContainer /> */}
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        TransitionComponent={TransitionLeft}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          Failed! Enter correct username and password.
        </Alert>
      </Snackbar>
      <div
        style={{
          backgroundImage: `url(${bgimg})`,
          backgroundSize: "cover",
          height: "100vh",
          color: "#f5f5f5",
        }}
      >
        <Box sx={boxstyle}>
          <Grid container>
            <Grid item xs={12} sm={12} lg={6} style={{ background: "white" }}>
              <Box
                style={{
                  backgroundImage: `url(${bg})`,
                  backgroundSize: "cover",
                  marginTop: "40px",
                  marginLeft: "15px",
                  marginRight: "15px",
                  height: "63vh",
                  color: "#f5f5f5",
                }}
              ></Box>
            </Grid>
            <Grid item xs={12} sm={12} lg={6}>
              <Box
                style={{
                  backgroundSize: "cover",
                  height: "70vh",
                  minHeight: "500px",
                  backgroundColor: "#3b33d5",
                }}
              >
                <ThemeProvider theme={darkTheme}>
                  <Container>
                    <Box height={35} />
                    <Box sx={center}>
                      <Avatar
                        sx={{ ml: "35px", mb: "4px", bgcolor: "#ffffff" }}
                      >
                        <LockOutlinedIcon />
                      </Avatar>
                      <Typography component="h1" variant="h4" style={{ textAlign: 'left' }}>
                        Sign In
                      </Typography>
                    </Box>
                    <Box
                      component="form"
                      noValidate
                      onSubmit={handleSubmit}
                      sx={{ mt: 2 }}
                    >
                      <Grid container spacing={1}>
                        <Grid item xs={12} sx={{ ml: "3em", mr: "3em" }}>
                          <FormControl sx={{ mt: 1, width: "100%" }} variant="outlined">
                            <TextField
                              className="email-bg"
                              required
                              fullWidth
                              id="email"
                              label="Email"
                              name="email"
                              value={email}
                              onChange={(e) => {
                                setEmail(e.target.value)
                                setError((preverr) => ({ ...preverr, email: "" }))
                              }}
                              autoComplete="email"
                              error={error.email}
                            />
                            {error.email && <span className="required-css">{error.email}</span>}
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sx={{ ml: "3em", mr: "3em" }}>
                          <FormControl sx={{ mt: 1, width: "100%" }} variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password" error={error.password}>Password *</InputLabel>
                            <OutlinedInput
                              required
                              fullWidth
                              name="password"
                              label="Password"
                              type={showPassword ? 'text' : 'password'}
                              value={password}
                              onChange={(e) => {
                                setPassword(e.target.value)
                                setError((preverr) => ({ ...preverr, password: "" }))
                              }}
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                  >
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                  </IconButton>
                                </InputAdornment>
                              }
                              id="password"
                              autoComplete="new-password"
                              error={error.password}
                            />
                            {error.password && <span className="required-css">{error.password}</span>}
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sx={{ ml: "3em", mr: "3em" }}>
                          <Stack direction="row" spacing={2}>
                            <Typography
                              variant="body1"
                              component="span"
                              onClick={() => {
                                navigate("/forgot-password");
                              }}
                              style={{ marginTop: "10px", cursor: "pointer" }}
                            >
                              Forgot password?
                            </Typography>
                          </Stack>
                        </Grid>
                        <Grid item xs={12} sx={{ ml: "5em", mr: "5em" }}>
                          {isloader ? (
                            <Button
                              type="submit"
                              variant="contained"
                              fullWidth="true"
                              size="large"
                              sx={{
                                mt: "10px",
                                mr: "20px",
                                borderRadius: 28,
                                color: "#ffffff",
                                minWidth: "170px",
                                backgroundColor: "#FF9A01",
                              }}
                            >
                              <div class="spinner-border" role="status">
                                <span class="sr-only" ></span>
                              </div>
                            </Button>
                          ) : (
                            <Button
                              type="submit"
                              onClick={handleLoginSubmit}
                              variant="contained"
                              fullWidth="true"
                              size="large"
                              sx={{
                                mt: "10px",
                                mr: "20px",
                                borderRadius: 28,
                                color: "#ffffff",
                                minWidth: "170px",
                                backgroundColor: "#FF9A01",
                              }}
                            >
                              Sign In
                            </Button>
                          )}
                        </Grid>
                      </Grid>
                    </Box>
                  </Container>
                </ThemeProvider>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </div>
    </div>
  );
}
