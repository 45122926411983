import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../config/config';
import Web3 from 'web3';
import { useMetaMask } from "metamask-react";
import DataTable from "react-data-table-component";
import { BsFillFileBarGraphFill, BsArrowRight } from 'react-icons/bs';
import { AiOutlineArrowDown } from 'react-icons/ai';

import { FiLogOut } from "react-icons/fi";
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getAllRewardUsers, withdrawTransactionDetails } from './api/authapis';
import PendingTransaction from './PendingTransaction';
import ReactPaginate from 'react-paginate';
import DUSDtokenAbi from './ABI/DUSDtokenAbi.json';
import Plan1Abi from './ABI/Plan1Abi.json';
import DemoCorePlanAbi from './ABI/DemoCorePlan.json';
import ToastApi from '../config/toastApi';

function Dashboard() {


  const [validUserToClaim, setValidUserToClaim] = useState([]);
  const [goldRankUsers, setGoldRankUsers] = useState([]);
  const [platinumRankUsers, setPlatinumRankUsers] = useState([]);
  const [diamondRankUsers, setDiamondRankUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [getRewardAmountFromWallet, setGetRewardAmountFromWallet] = useState();


  var web3js = '';


  web3js = new Web3(new Web3.providers.HttpProvider(config.YOUR_NODE_RPC_URL));

  const DemoCorePlanContract = new web3js.eth.Contract(DemoCorePlanAbi, config.DEMO_CORE_PLAN_CONTRACT_ADDRESS);
  const DUSD_Contract_address = new web3js.eth.Contract(DUSDtokenAbi, config.DUSD_TOKEN_ADDRESS);


  const foundTheRankingData = async () => {
    try {
      const GetRewardAmount = await DUSD_Contract_address.methods.balanceOf(config.D_REWARD_STORED_ADDRESS).call();
      const amountInEther = web3js.utils.fromWei(GetRewardAmount, 'ether')


      setGetRewardAmountFromWallet(amountInEther)
      const getRankData = await getAllRewardUsers();

      const levelOfRank = getRankData.data.data;

      const goldRankUsers = [];
      const platinumRankUsers = [];
      const diamondRankUsers = [];
      const pushRankDataInArray = [];
      levelOfRank.forEach(element => {

        const objectValue = {};
        // const amountInEther = web3js.utils.fromWei(element.PerCentage_20_Amount, 'ether');

        objectValue['amount'] = element.PerCentage_20_Amount;
        objectValue['toAddress'] = element.User_Address;
        objectValue['id'] = element._id;
        objectValue['User_Id'] = element.User_Id;
        objectValue['ranking'] = element.Rank_Status;
        // Push user to respective rank array based on Rank_Status
        if (element.Rank_Status === "GOLD") {
          goldRankUsers.push(objectValue);
        } else if (element.Rank_Status === "PLATINUM") {
          platinumRankUsers.push(objectValue);
        } else if (element.Rank_Status === "DIAMOND") {
          diamondRankUsers.push(objectValue);
        }
        pushRankDataInArray.push(objectValue);

      });

      // Set state for each rank
      setGoldRankUsers(goldRankUsers);
      setPlatinumRankUsers(platinumRankUsers);
      setDiamondRankUsers(diamondRankUsers);
      setValidUserToClaim(pushRankDataInArray);

    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {


    foundTheRankingData();
    setTimeout(() => setLoading(false), 3000);

    // Set interval to call the function every 5 seconds
    const intervalId = setInterval(foundTheRankingData, 5000);

    // Cleanup function to clear interval when component unmounts
    return () => clearInterval(intervalId);

  }, [])

  const sliceAddress = (address, startLength = 6, endLength = 6) => {
    try {
      if (!address) return '';

      const prefix = address.slice(0, startLength);
      const suffix = address.slice(-endLength);

      return `${prefix}...${suffix}`;
    } catch (error) {

    }

  };

  const [overviewGoldTansactionPage, setoverviewGoldTansactionPage] = useState(0);
  const [overviewPlatinumTansactionPage, setoverviewPlatinumTansactionPage] = useState(0);
  const [overviewDiamondTansactionPage, setoverviewDiamondTansactionPage] = useState(0);

  const TransactionPerPage = config.TRANSACTION_INDEX_PAGE_NUM;
  const numberOfGoldTansactionDetails = overviewGoldTansactionPage * TransactionPerPage;
  const numberOfPlatinumdTansactionDetails = overviewPlatinumTansactionPage * TransactionPerPage;
  const numberOfDiamondTansactionDetails = overviewDiamondTansactionPage * TransactionPerPage;

  const goldTotalPages = Math.ceil(validUserToClaim.filter(item => item.ranking === 'GOLD').length / TransactionPerPage); // Adjust filtering based on ranking
  const platinumTotalPages = Math.ceil(validUserToClaim.filter(item => item.ranking === 'PLATINUM').length / TransactionPerPage); // Adjust filtering based on ranking
  const diamondTotalPages = Math.ceil(validUserToClaim.filter(item => item.ranking === 'DIAMOND').length / TransactionPerPage); // Adjust filtering based on ranking
  const goldChangePage = ({ selected }) => {
    setoverviewGoldTansactionPage(selected);
  };

  const platinumChangePage = ({ selected }) => {
    setoverviewPlatinumTansactionPage(selected);
  };

  const diamondChangePage = ({ selected }) => {
    setoverviewDiamondTansactionPage(selected);
  };
  const startGoldIndex = overviewGoldTansactionPage * TransactionPerPage;
  const startPlatinumIndex = overviewPlatinumTansactionPage * TransactionPerPage;
  const startDiamondIndex = overviewDiamondTansactionPage * TransactionPerPage;


  document.querySelectorAll('.total-value-earned-per .card').forEach(card => {
    card.addEventListener('click', function() {
      document.querySelectorAll('.total-value-earned-per .card').forEach(c => c.classList.remove('active'));
      this.classList.add('active');
    });
  });
  

  return (
    <div className='dashboard-section'>
      <div class="container py-4">

        <h3 className='fw-bold ' style={{ color: "rgb(8 29 68)" }}>ADMIN DASHBOARD</h3>
      </div>


      <div class="container mt-4">
        <div class="row justify-content-center">
          <div class="col">
            <div class="gradient-box d-flex flex-column">
              <h4 className='mb-0 fw-bold h-four'>Total Reward </h4><br />
              {validUserToClaim.length === 0 ? (

                <p className='mb-0 t-reward h-four'>0</p>

              ) : (

                validUserToClaim.length > 0 &&
                (() => {
                  let totalRwaredAmount = 0;
                  validUserToClaim

                    .forEach(item => {
                      const amount = parseFloat(item.amount);
                      if (!isNaN(amount)) {
                        totalRwaredAmount += amount;
                      } else {
                        console.error(`Invalid amount found: ${item.amount}`);
                      }
                    });

                  return (

                    <p className='mb-0 t-reward h-four'>{totalRwaredAmount.toFixed(2)}</p>


                  );
                })()

              )}
            </div>


          </div>
        </div>
      </div>

      <div class="container mt-5">
        <div className="row">
          <div className='col'>
            <h2 className=''>Reward Wallet Amount : <span className=''>{Number(getRewardAmountFromWallet).toFixed(2)}</span> </h2>
          </div>
        </div>
      </div>
      <div class="container mt-5 total-value-earned-per">
        <ul className='p-0' id="pills-tab" role="tablist">
          <div class="row row-cols-1 row-cols-lg-3 g-4">
            <div class="col">
              <div class="nav-item" role="presentation">
                <button class="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">
                  <div class="card h-100 border-0 py-2 px-3" style={{ backgroundColor: "#FEDADA" }} >
                    <div class="card-body">
                      <BsFillFileBarGraphFill className='total-value-earned-icon mb-4' style={{ backgroundColor: "red" }} />
                      <h5 class="card-title">GOLD</h5>
                      <p class="card-text">No of users: {goldRankUsers.length}</p>
                      <p className='text-end mb-0'><BsArrowRight className='card-arrow' /></p>
                    </div>
                  </div>
                </button>
              </div>
            </div>
            <div class="col">
              <div class="nav-item" role="presentation">
                <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false"
                >
                  <div class="card h-100 border-0 py-2 px-3" style={{ backgroundColor: "#FFFAD0" }}>
                    <div class="card-body">
                      <BsFillFileBarGraphFill className='total-value-earned-icon mb-4' style={{ backgroundColor: "orange" }} />
                      <h5 class="card-title">PLATINUM</h5>
                      <p class="card-text">No of users: {platinumRankUsers.length}</p>
                      <p className='text-end mb-0'><BsArrowRight className='card-arrow' /></p>
                    </div>
                  </div>
                </button>
              </div>
            </div>
            <div class="col">
              <div class="nav-item" role="presentation">
                <button class="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact"
                  type="button" role="tab" aria-controls="pills-contact" aria-selected="false">
                  <div class="card h-100 border-0 py-2 px-3" style={{ backgroundColor: "#D4FFD6" }}>
                    <div class="card-body">
                      <BsFillFileBarGraphFill className='total-value-earned-icon mb-4' style={{ backgroundColor: "green" }} />
                      <h5 class="card-title">DIAMOND</h5>
                      <p class="card-text">No of users: {diamondRankUsers.length} </p>
                      <p className='text-end mb-0'><BsArrowRight className='card-arrow' /></p>
                    </div>
                  </div>
                </button>
              </div>
            </div>

          </div>
        </ul>
      </div>


      <div class="tab-content mt-5" id="pills-tabContent">
        <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
          <div class="container mt-4 pb-5">
            <div class="row justify-content-center">
              <div class="col">
                {loading ? (
                  <>
                    <h2>GOLD</h2>
                    <div class="spinner-grow text-warning" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                    <div class="spinner-grow text-secondary" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </>
                ) : (
                  <>
                    <table class="reward-table">
                      <tr>
                        <th>SI.no</th>
                        <th>To Address</th>
                        <th>ID</th>
                        <th>Rank</th>
                        <th>Amount</th>
                        {/* <th>Claim</th> */}
                      </tr>
                      <tbody>
                        {
                          goldRankUsers.length === 0 ? (
                            <>
                              <tr>
                                <td></td>
                                <td></td>
                                <td>Record Not Found</td>
                                <td></td>
                                <td></td>
                                {/* <th></th> */}
                              </tr>
                            </>
                          ) : (
                            validUserToClaim.length > 0 &&
                            validUserToClaim
                              .filter(item => item.ranking === 'GOLD')
                              .slice(numberOfGoldTansactionDetails, numberOfGoldTansactionDetails + TransactionPerPage)
                              .map((item, index) => {
                                const transactionIndex = startGoldIndex + index + 1;
                                return (
                                  <tr key={index}>
                                    <td data-th="Supplier Code">
                                      {transactionIndex}
                                    </td>
                                    <td data-th="Supplier Name">
                                      {sliceAddress(item.toAddress)}
                                    </td>
                                    <td data-th="Invoice Number">
                                      {item.User_Id}
                                    </td>
                                    <td data-th="Invoice Number">
                                      {item.ranking}
                                    </td>
                                    <td data-th="Invoice Number">
                                      {Number(item.amount).toFixed(2)}
                                    </td>
                                    {/* <td data-th="Invoice Date" >
                            <button onClick={() => { pickIdFromTheValidUser(item.toAddress, item.amount, config.ADMIN_WALLET_ADDRESS, item.User_Id, item.ranking) }} className="btn w-30" style={{ background: "#031228", color: "white" }}>
                                CLAIM
                            </button>
                        </td> */}
                                  </tr>
                                );
                              })
                          )
                        }


                      </tbody>
                    </table>
                  </>
                )}
                <ReactPaginate
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                  pageCount={goldTotalPages}
                  onPageChange={goldChangePage}
                  containerClassName={"navigationButtons"}
                  previousLinkClassName={"previousButton"}
                  nextLinkClassName={"nextButton"}
                  disabledClassName={"navigationDisabled"}
                  activeClassName={"navigationActive"}
                />
                {/* -----------------------------table------------------------- */}
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
          <div class="container mt-4 pb-5">
            <div class="row justify-content-center">
              <div class="col">
                {loading ? (
                  <>
                    <h2>PLATINUM</h2>
                    <div class="spinner-grow text-warning" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                    <div class="spinner-grow text-secondary" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </>
                ) : (
                  <>
                    <table class="reward-table">
                      <tr>
                        <th>SI.no</th>
                        <th>To Address</th>
                        <th>ID</th>
                        <th>Rank</th>
                        <th>Amount</th>
                        {/* <th>Claim</th> */}
                      </tr>
                      <tbody>
                        {
                          platinumRankUsers.length == 0 ? (
                            <>
                              <tr>
                                <td></td>
                                <td></td>
                                <td>Record Not Found</td>
                                <td></td>
                                <td></td>
                                {/* <th></th> */}
                              </tr>
                            </>
                          ) : (
                            validUserToClaim.length > 0 &&
                            validUserToClaim
                              .filter(item => item.ranking === 'PLATINUM')
                              .slice(numberOfPlatinumdTansactionDetails, numberOfPlatinumdTansactionDetails + TransactionPerPage)
                              .map((item, index) => {
                                const transactionIndex = startPlatinumIndex + index + 1;

                                return (
                                  <tr key={index}>
                                    <td data-th="Supplier Code">
                                      {transactionIndex}
                                    </td>
                                    <td data-th="Supplier Name">
                                      {sliceAddress(item.toAddress)}
                                    </td>

                                    <td data-th="Invoice Number">
                                      {item.User_Id}
                                    </td>
                                    <td data-th="Invoice Number">
                                      {item.ranking}
                                    </td>
                                    <td data-th="Invoice Number">
                                      {Number(item.amount).toFixed(2)}
                                    </td>
                                    {/* <td data-th="Invoice Date" >
                                <button onClick={() => { pickIdFromTheValidUser(item.toAddress, item.amount, config.ADMIN_WALLET_ADDRESS, item.User_Id, item.ranking) }} class="btn w-30" style={{ background: "#031228", color: "white" }}>
                                  CLAIM
                                </button>

                              </td> */}
                                  </tr>
                                );
                              })
                          )}

                      </tbody>
                    </table>
                  </>
                )}
                <ReactPaginate
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                  pageCount={platinumTotalPages}
                  onPageChange={platinumChangePage}
                  containerClassName={"navigationButtons"}
                  previousLinkClassName={"previousButton"}
                  nextLinkClassName={"nextButton"}
                  disabledClassName={"navigationDisabled"}
                  activeClassName={"navigationActive"}
                />
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
          <div class="container mt-4 pb-5">
            <div class="row justify-content-center">
              <div class="col">
                {loading ? (
                  <>
                    <h2>DIAMOND</h2>
                    <div class="spinner-grow text-warning" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                    <div class="spinner-grow text-secondary" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </>
                ) : (
                  <>
                    <table class="reward-table">
                      <tr>
                        <th>SI.no</th>
                        <th>To Address</th>
                        <th>ID</th>
                        <th>Rank</th>
                        <th>Amount</th>
                        {/* <th>Claim</th> */}
                      </tr>
                      <tbody>
                        {
                          diamondRankUsers.length == 0 ? (
                            <>
                              <tr>
                                <td></td>
                                <td></td>
                                <td>Record Not Found</td>
                                <td></td>
                                <td></td>
                                {/* <th></th> */}
                              </tr>
                            </>
                          ) : (
                            validUserToClaim.length > 0 &&
                            validUserToClaim
                              .filter(item => item.ranking === 'DIAMOND')
                              .slice(numberOfDiamondTansactionDetails, numberOfDiamondTansactionDetails + TransactionPerPage)
                              .map((item, index) => {
                                const transactionIndex = startDiamondIndex + index + 1;

                                return (
                                  <tr key={index}>
                                    <td data-th="Supplier Code">
                                      {transactionIndex}
                                    </td>
                                    <td data-th="Supplier Name">
                                      {sliceAddress(item.toAddress)}
                                    </td>

                                    <td data-th="Invoice Number">
                                      {item.User_Id}
                                    </td>
                                    <td data-th="Invoice Number">
                                      {item.ranking}
                                    </td>
                                    <td data-th="Invoice Number">
                                      {Number(item.amount).toFixed(2)}
                                    </td>
                                    {/* <td data-th="Invoice Date" >
                                <button onClick={() => { pickIdFromTheValidUser(item.toAddress, item.amount, config.ADMIN_WALLET_ADDRESS, item.User_Id, item.ranking) }} class="btn w-30" style={{ background: "#031228", color: "white" }}>
                                  CLAIM
                                </button>

                              </td> */}
                                  </tr>
                                );
                              })
                          )}

                      </tbody>
                    </table>
                  </>
                )}
                <ReactPaginate
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                  pageCount={diamondTotalPages}
                  onPageChange={diamondChangePage}
                  containerClassName={"navigationButtons"}
                  previousLinkClassName={"previousButton"}
                  nextLinkClassName={"nextButton"}
                  disabledClassName={"navigationDisabled"}
                  activeClassName={"navigationActive"}
                />
              </div>
            </div>
          </div>
        </div>

      </div>


      {/* <DataTable className=' text-center'
        columns={columns}
        pagination
        data={datadetails}
        paginationComponentOptions={paginationOptions}
      /> */}

    </div>
  );
}

export default Dashboard;