import React, { useState, useMemo, useEffect } from 'react';
import { MDBTable, MDBTableHead, MDBTableBody } from 'mdb-react-ui-kit';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import "./Editor.css"
import configration from '../config/config';
import isEmpty from 'is-empty';
import { ToastContainer, toast } from 'react-toastify';

function CreateFaq() {
	const [logs, setLogs] = useState([]);

	const [show, setShow] = useState(false);
	const [list, setList] = useState([]);
	const [listDetails, setListDetails] = useState({
		_id: '',
		type: '',
		title: '',
		description: '',
	});
	const [editfaq, setEditfaq] = useState({
		_id: '',
		type: '',
		title: '',
		description: '',
	});
	const [error, setError] = useState({})
	const [remainingCharacters, setRemainingCharacters] = useState(0);



	// find all details
	const find = async () => {
		try {
			const response = await axios.get(`${configration.localhostBackend}get-faq`);
			// console.log("response===", response);
			const value = response.data.list;
			setList(value);
		} catch (error) {
			console.error('Error fetching data:', error);
		}
	};

	useEffect(() => {
		find();
	}, []);

	const handleClose = () => {
		setShow(false);
		setError({})
		setEditfaq({
			_id: "",
			title: "",
			description: ""
		})
		setRemainingCharacters(0)
	};
	// create 
	const handleSubmit = async (e) => {
		e.preventDefault();


 


		const Validateerror = {}

		if (isEmpty(listDetails.title)) {
			Validateerror.title = "Title is required"
		}
		// else if (listDetails.title.length > 100) {
		//     Validateerror.title = 'Title must be 150 characters or less';
		// }

		// const description = listDetails.description
		// const textWithoutNbsp = description.replace(/&nbsp;/g, '');

		// console.log("textWithoutNbsp---", textWithoutNbsp);
		// const textWithoutTags = textWithoutNbsp.replace(/<\/?[^>]+(>|$)/g, '')
		// let lettersAndSpaces = textWithoutTags.replace(/[^a-zA-Z0-9\s,."'$;]/g, '');

		if (isEmpty(listDetails.description)) {
			Validateerror.description = "description is required"
		} else if (remainingCharacters > 500) {
			Validateerror.description = 'Description must be 500 characters or less';
		}
		setError(Validateerror)

		try {
			if (listDetails._id) {

				if (Object.keys(Validateerror).length === 0) {
					const response = await axios.post(`${configration.localhostBackend}update-faq`, {
						id: listDetails._id,
						title: listDetails.title,
						description: listDetails.description
					})

					toast.success("FAQ update successfully")
					setTimeout(() => {
						handleClose()
					}, 1000);
					find()
				}
			}
			else {

				if (Object.keys(Validateerror).length === 0) {

					const response = await axios.post(`${configration.localhostBackend}create-faq`, listDetails)

					toast.success("FAQ added successfully")
					setTimeout(() => {
						handleClose()
					}, 1000);
					find()
				}
			}


		} catch (error) {
			console.error('Error:', error);
		}
	};

	const handleUpdate = async (id) => {
		try {

			const response = await axios.post(`${configration.localhostBackend}finddata-faq`, { id })

			setShow(true)
			setListDetails({
				_id: response.data.data._id,
				title: response.data.data.title,
				description: response.data.data.description
			})

			const textWithoutNbsp = response.data.data.description.replace(/&nbsp;/g, '');
			const textWithoutTags = textWithoutNbsp.replace(/<\/?[^>]+(>|$)/g, '');

			setRemainingCharacters(textWithoutTags.length)
		} catch (error) {
			console.error('Error:', error);
		}
	};
	const handleDelete = async (id) => {
		const response = await axios.post(`${configration.localhostBackend}delete-faq`, { id })
			.then((res) => {
				toast.success("Deleted Successfully")

				find()
				// window.location.reload()

			})
	}

	const handleChange = (event) => {
		// setListDetails((prevProps) => ({ ...prevProps, [event.target.name]: event.target.value }));
		setListDetails((prevProps) => {
			return { ...prevProps, [event.target.name]: event.target.value };
		});

		// setError((prevErr) => ({ ...prevErr, title: "" }));
		setError({ ...error, [event.target.name]: '' })
	};

	const config = useMemo(
		() => ({
			readOnly: false,
		}),
		[]
	);

	const onEditorChange = (event, editor) => {
		const data = editor.getData();

		const textWithoutNbsp = data.replace(/&nbsp;/g, '');


		// Remove HTML tags
		const textWithoutTags = textWithoutNbsp.replace(/<\/?[^>]+(>|$)/g, '');

		const characterLimit = 500;
		setListDetails((prevProps) => ({ ...prevProps, description: data }));
		setError((preverr) => ({ ...preverr, description: "" }))
		if (textWithoutTags.length > characterLimit) {
			setRemainingCharacters(textWithoutTags.length);

		} else {
			setListDetails((prevDetails) => ({ description: textWithoutTags, ...prevDetails }));
			setRemainingCharacters(textWithoutTags.length);
		}
	};

	return (
		<div className='faq-contents'>
			<h1 className='py-5' style={{ color: "rgb(8, 29, 68)" }}>Frequently Asked Questions</h1>
			<Modal show={show} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>{listDetails._id ? 'Edit FAQ' : 'Add FAQ'}</Modal.Title>
				</Modal.Header>
				<Form onSubmit={handleSubmit}>
					<Modal.Body>
						{/* <Form.Label>Type</Form.Label>
						<Form.Select
							aria-label="Default select example"
							value={listDetails.type}
							name="type"
							onChange={(e) => handleChange(e)}
						>
							<option></option>
							<option value="common">Common</option>
						</Form.Select> */}
						<Form.Group className="mb-3" controlId="formBasicPassword">
							<Form.Label>Title</Form.Label>
							<Form.Control
								value={listDetails.title}
								type="text"
								placeholder="Title"
								name="title"
								onChange={(e) => handleChange(e)}
							/>
							{error.title && <span style={{ color: 'red' }}>{error.title}</span>}
						</Form.Group>
						<Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
							<Form.Label>Description</Form.Label>
							<CKEditor
								editor={ClassicEditor}
								config={config}
								data={listDetails.description}
								onChange={onEditorChange}
							/>
							Characters Remaining: {remainingCharacters >= 0 ? remainingCharacters : 0}/{500}
						</Form.Group>
						{error.description && <span style={{ color: 'red' }}>{error.description}</span>}
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={handleClose}>
							Close
						</Button>

						<Button variant="primary" type="submit">
							Submit
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>

			<Button onClick={() => { setShow(true); setListDetails({}) }} style={{ background: "rgb(8 29 68)", color: "white", margin: "20px" }}>
				Add FAQ
			</Button>
			<div className='d-flex justify-content-center'>
				<MDBTable bordered striped>
					<MDBTableHead>
						<tr>
							<th scope="col" w-25>S.NO</th>
							<th scope="col" w-25>Title</th>
							<th scope="col" w-25>Description</th>
							<th scope="col" w-25>Action</th>
						</tr>
					</MDBTableHead>
					<MDBTableBody>
						{Array.isArray(list) && list.length > 0 ? (
							list.map((data, index) => (
								<tr key={index}>
									<td>#{index + 1}</td>
									<td style={{ width: '400px' }}>{data.title}</td>
									<td style={{ width: '400px' }} dangerouslySetInnerHTML={{ __html: data.description }}></td>
									<td style={{ width: '100px' }}>
										<div className='d-flex gap-2'>
											<div>
												<Button onClick={() => handleUpdate(data._id)} style={{ background: "rgb(8 29 68)", color: "white" }}>Edit</Button>
											</div>
											<div>
												{/* Button trigger modal */}
												<button style={{ background: "rgb(155 33 33)", color: "white" }} type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target={`#staticBackdrop-${index}`}>
													Delete
												</button>
												{/* Modal */}
												<div className="modal fade" id={`staticBackdrop-${index}`} data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby={`staticBackdropLabel-${index}`} aria-hidden="true">
													<div className="modal-dialog modal-dialog-centered">
														<div className="modal-content">
															<div class="modal-header border-0">
																<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
															</div>
															<div className="modal-body">
																<h4 className=''>Are you sure do you want to delete ?</h4>
															</div>
															<div class="modal-footer border-0 justify-content-center pb-3">
																<Button type="button" onClick={() => handleDelete(data._id)} class="btn btn-primary px-3" data-bs-dismiss="modal">Yes</Button>
																<button type="button" class="btn btn-danger px-3" data-bs-dismiss="modal">No</button>
															</div>
														</div>
													</div>
												</div>


											</div>
										</div>
									</td>
								</tr>
							))
						) : (
							<>
							<td colSpan="4">Data not found</td>
							</>
						)}
					</MDBTableBody>
				</MDBTable>
			</div>
		</div>
	);
}

export default CreateFaq;
