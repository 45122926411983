import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import config from '../config/config';
import isEmpty from 'is-empty';
import Web3 from 'web3';
import $ from 'jquery';
import ToastApi from '../config/toastApi';
import DemoCorePlanAbi from './ABI/DemoCorePlan.json';
import DUSDtokenAbi from './ABI/DUSDtokenAbi.json';
import Window_Ethereum_Config_Api from '../config/windowEthereumConfig';
import { getwhitelsdetails, UserWhitelistprocess, userRegister, ConfirmExternal, InfluencerDeletecall } from "./api/authapis"
import { toast } from 'react-toastify';
import { AspectRatio } from '@mui/icons-material';
// import { Modal } from 'bootstrap';
import ReactPaginate from 'react-paginate';
import { FaTelegramPlane, FaWhatsapp } from "react-icons/fa";
import { AiOutlineWechat } from "react-icons/ai";
import { FaCopy } from "react-icons/fa6";

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function Whitelistrequest() {

  const [userDetails, setUserDetails] = useState([])
  const [connectedWallet, setConnectedWallet] = useState(null);
  const [dchainid, setDchainid] = useState(null);
  const [socialnet, setSocialnet] = useState([])
  const [username, setUsername] = useState(null)
  const [influAddress, setInfluAddress] = useState("")
  const [joinType, setJoinType] = useState("")
  const [selecterror, setSelecterror] = useState("")
  const [isloading, setIsloading] = useState(false)
  const toastCheck = React.useRef(null);
  const [show, setShow] = useState(false);


  const [overviewTansactionPage, setoverviewTansactionPage] = useState(0);

  const TransactionPerPage = config.TRANSACTION_INDEX_PAGE_NUM;

  const numberOfTansactionDetails = overviewTansactionPage * TransactionPerPage;

  const totalPages = Math.ceil(userDetails.length / TransactionPerPage);

  const changePage = ({ selected }) => {

    setoverviewTansactionPage(selected);
  };

  const startIndex = overviewTansactionPage * TransactionPerPage;

  var web3js = '';

  if (window.ethereum) {
    if (connectedWallet != null && dchainid != config.DCXCHAIN_HEX_ID) {
      web3js = new Web3(new Web3.providers.HttpProvider(config.YOUR_NODE_RPC_URL));
    } else if (connectedWallet == null) {
      web3js = new Web3(new Web3.providers.HttpProvider(config.YOUR_NODE_RPC_URL));
    } else {
      web3js = new Web3(window.ethereum);
    }
    // web3js = new Web3(window.ethereum);
    // web3js = new Web3(new Web3.providers.HttpProvider(config.YOUR_NODE_RPC_URL));
  } else {
    web3js = new Web3(new Web3.providers.HttpProvider(config.YOUR_NODE_RPC_URL));
  }

  const DemoCorePlanContract = new web3js.eth.Contract(DemoCorePlanAbi, config.DEMO_CORE_PLAN_CONTRACT_ADDRESS);
  const DUSD_Contract_address = new web3js.eth.Contract(DUSDtokenAbi, config.DUSD_TOKEN_ADDRESS);

  const Onclose = () => {
    setInfluAddress("")
    setJoinType("")
    setSelecterror("")
    setShow(false)
  }


  const Whitelsdetails = async () => {
    try {
      const response = await getwhitelsdetails();
      // console.log("getwhitelsdetails", response);
      if (response.status === true) {
        const pushWhitelsArray = [];
        const DataList = response.data
        DataList.forEach(element => {
          // console.log("element====", element);
          const objectValue = {};

          if (element.JoinCheck !== "Joined") {
            objectValue['_id'] = element._id;
            objectValue['name'] = element.UserName;
            objectValue['address'] = element.UserAddress;
            objectValue['contactType'] = element.contactDetails;
            objectValue['socialLink'] = [{
              Telegram: element.TelegramName,
              TelegramCount: element.TelegramSubcount,
              Discord: element.Discord,
              DiscortMemCount: element.DiscortMemCount,
              Xtwitter: element.Xtwitter,
              XfollowCount: element.XfollowCount,
              Youtube: element.YoutubeName,
              YoutubeCount: element.YoutubeSubcount
            }]
            objectValue['joincheck'] = element.JoinCheck;
            objectValue['date'] = element.date


            pushWhitelsArray.push(objectValue)
          } else {
            console.log("joined data");
          }

        });

        setUserDetails(pushWhitelsArray);
      } else {
        setUserDetails([])
      }

    } catch (error) {
      console.log("error", error);
    }
  }

  useEffect(() => {
    Whitelsdetails()
  }, [])

  async function SocialLinksfn(data) {
    try {

      if (data) {
        setSocialnet(data[0])
      }
    } catch (error) {

    }
  }

  const SelectChange = async (event) => {
    try {

      const type = event.target.value
      if (type) {
        setJoinType(event.target.value)
        setSelecterror("")
      }

    } catch (error) {

    }
  }

  async function AddressGet(address, id) {
    try {
      const userid = id
      const Res = await axios.get(`${config.localhostBackend}v1/ConfirmInfluencer`, {
        params: { user_id: userid }
      })

      let status = Res.data.status
      const UserDet = Res.data.UserData;

      if (status === true) {
        let checkAddress = UserDet.UserAddress
        let userName = UserDet.UserName
        if (checkAddress === address) {
          setInfluAddress(checkAddress)
          setUsername(userName)
        }
      }

    } catch (error) {
      console.log("AddressGet", error);
    }
  }


  const addCustomNetwork = async () => {
    try {
      await window.ethereum.request({
        method: Window_Ethereum_Config_Api.wallet_switchEthereumChain,
        params: [{ chainId: config.DCXCHAIN_HEX_ID }]
      });
      toast.success(ToastApi.AddNetworkSuccessToast);
    } catch (switchError) {
      if (switchError.code === 4902) {
        try {
          await window.ethereum.request({
            method: Window_Ethereum_Config_Api.wallet_addEthereumChain,
            params: [
              {
                chainId: config.DCXCHAIN_HEX_ID,
                chainName: config.DCX_NETWORK_NAME,
                nativeCurrency: {
                  name: config.DCX_NETWORK_NAME,
                  symbol: config.DCX_SYMBOL,
                  decimals: config.DCX_DECIMAL,
                },
                rpcUrls: [config.DCXRPC_URL],
                blockExplorerUrls: [config.DCX_BLOCKEXPLORER],
              },
            ],
          });
          toast.success(ToastApi.AddNetworkSuccessToast);
        } catch (addError) {
          console.log("error", addError);
        }
      }
    }
  };

  const checkConnectedWallet = async () => {
    try {
      if (window.ethereum) {
        const accounts = await window.ethereum.request({ method: Window_Ethereum_Config_Api.eth_accounts });
        let checkSumMetamaskAddress = web3js.utils.toChecksumAddress(accounts?.[0]);

        setConnectedWallet(checkSumMetamaskAddress || null);

        const chainId = await window.ethereum.request({ method: Window_Ethereum_Config_Api.eth_chainId });
        setDchainid(chainId)

      }
      else {
        console.log("Metamask Not install");
      }

    } catch (error) {
      console.error('Error checking connected wallet:', error);
    }
  };

  const connectWallet = async () => {
    try {
      if (window.ethereum) {
        const chainId = await window.ethereum.request({ method: Window_Ethereum_Config_Api.eth_chainId });

        if (chainId !== config.DCXCHAIN_HEX_ID) {
          await addCustomNetwork();
        } else {
          await window.ethereum.request({ method: Window_Ethereum_Config_Api.eth_requestAccounts });
          checkConnectedWallet();
        }
      }
      else {
        toast.warn(ToastApi.PleaseInstallMetamsk)
        setTimeout(() => {
          handleInstallMetaMask('https://metamask.io/download.html')
        }, 1000);
      }
    } catch (error) {
      toast.error(ToastApi.WalletConnectionError);
    }
  };

  const handleInstallMetaMask = async (url) => {
    const newWindow = window.open(url, '_blank');
    if (newWindow) {
      newWindow.focus()
    }
  };


  useEffect(() => {



    const eventAccountWatch = async () => {
      if (window.ethereum) {
        const accounts = await window.ethereum.request({ method: Window_Ethereum_Config_Api.eth_accounts });

        if (accounts.length === 1) {
          let checkSumMetamaskAddress = web3js.utils.toChecksumAddress(accounts[0]);
          setConnectedWallet(checkSumMetamaskAddress);

          const chainId = await window.ethereum.request({ method: Window_Ethereum_Config_Api.eth_chainId });
          setDchainid(chainId)

        }

        // else if (accounts.length > 1) {
        //   if (!toast.isActive(toastCheck.current)) {
        //     if (toastCheck.current === null) {
        //       toastCheck.current = toast.warning(ToastApi.ConnectAnyOneAccount)
        //     }
        //   }
        // }
      }

      // else if (accounts.length == 0) {
      //     toast.warning(ToastApi.ConnectYourWallet);
      // }
    }
    eventAccountWatch();
  }, [])



  function formatDate(dateObject) {
    const year = dateObject.getUTCFullYear();
    const month = String(dateObject.getUTCMonth() + 1).padStart(2, '0');
    const day = String(dateObject.getUTCDate()).padStart(2, '0');
    const hours = String(dateObject.getUTCHours()).padStart(2, '0');
    const minutes = String(dateObject.getUTCMinutes()).padStart(2, '0');
    const seconds = String(dateObject.getUTCSeconds()).padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  const sliceAddress = (address, startLength = 6, endLength = 6) => {
    try {
      if (!address) return '';

      const prefix = address.slice(0, startLength);
      const suffix = address.slice(-endLength);

      return `${prefix}...${suffix}`;
    } catch (error) {

    }
  };

  const RegistorForExternal = async (Address) => {
    try {
      const chainId = await window.ethereum.request({ method: Window_Ethereum_Config_Api.eth_chainId });
      const WhiteUserAddress = Address;

      if (chainId !== config.DCXCHAIN_HEX_ID) {
        return switchNetwork()
      }
      if (!connectedWallet) {
        // Show a toast when the wallet is not connected
        toast.error(ToastApi.walletConnectText);
        return;
      }

      // if (connectedWallet !== config.ADMIN_WALLET_ADDRESS) {
      //   toast.warn(`Please conncet with address ${sliceAddress(config.ADMIN_WALLET_ADDRESS)}`);
      //   return;
      // }

      if (connectedWallet) {
        const WalletBalanceAmt=await DUSD_Contract_address.methods.balanceOf(connectedWallet).call();
        const balDusd= await web3js.utils.fromWei(WalletBalanceAmt,"ether")

        if (Number(balDusd) < 15) {
          toast.warn(`insufficient DUSD balance`)
          return;
        } 
      }
      const referralId = 1
      setIsloading(true)

      // Convert the value to a number
      // const referralId = parseInt(getReferralIdToRegister, 10);


      let checkSumMetamaskAddress = web3js.utils.toChecksumAddress(WhiteUserAddress);



      // Check if the conversion is successful and it's a valid number
      if (!isNaN(referralId)) {
        try {


          // Check if the user is already registered
          const checkUserRegisteredExists = await DemoCorePlanContract.methods.isUserExists(WhiteUserAddress).call();

          if (checkUserRegisteredExists) {
            toast.error(ToastApi.UserAlreadyRegistered);
            setIsloading(false)
          } else {
            // Fetch the upline referral address
            const getUplineReferralAddressByID = await DemoCorePlanContract.methods.idToAddress(referralId).call();


            const registrationToastErrorMessage = config.REGISTRATION_ZERO_ADDRESS;

            if (getUplineReferralAddressByID === registrationToastErrorMessage) {
              toast.warning(ToastApi.ReferralIdNotFound);
              setIsloading(false)
            } else {
              const gasPriceWei = web3js.utils.toWei('20', 'gwei');
              await approvedusd(connectedWallet).then(async (res) => {
                if (res == true) {
                  const alreadyApprovedContract = await DemoCorePlanContract.methods.registrationFor(checkSumMetamaskAddress, getUplineReferralAddressByID)
                    .send({
                      from: connectedWallet,
                      gasPrice: gasPriceWei,
                      nonce: await web3js.eth.getTransactionCount(connectedWallet),
                    });
                  // console.log('alreadyApprovedContract----', alreadyApprovedContract);
                  // console.log('alreadyApprovedContract.events', alreadyApprovedContract.events)

                  if (alreadyApprovedContract.transactionHash && alreadyApprovedContract.status == 1n) {

                    const txHash = alreadyApprovedContract.transactionHash;
                    const status = alreadyApprovedContract.status;

                    const returnContractValues = alreadyApprovedContract.events.Registration.returnValues;
                    const registeredAmount = alreadyApprovedContract.events.rewardpercentage.returnValues;
                    const xCoreAmount = alreadyApprovedContract.events.xcoreuserincome.returnValues;
                    const xcoresplit = alreadyApprovedContract.events.xcoresplit.returnValues;
                    const requestBody = {
                      txHash: alreadyApprovedContract.transactionHash,
                      status: alreadyApprovedContract.status,
                      returnContractValues: alreadyApprovedContract.events.Registration.returnValues,
                      registeredAmount: alreadyApprovedContract.events.rewardpercentage.returnValues,
                      xCoreAmount: alreadyApprovedContract.events.xcoreuserincome.returnValues,
                      xcoresplit: alreadyApprovedContract.events.xcoresplit.returnValues,
                      alreadyApprovedContract: alreadyApprovedContract // If you need the entire object
                    };

                    const Resquestexternalline = {
                      userName: username,
                      Cycleline: "External",
                      returnContractValues: alreadyApprovedContract.events.Registration.returnValues,
                    }

                    const registerDataSave = await userRegister(requestBody);



                    const ExternalDataSave = await ConfirmExternal(Resquestexternalline)


                    if (registerDataSave && ExternalDataSave) {
                      toast.success(ToastApi.SuccessfullyRegistered);
                      setIsloading(false)
                      Onclose()
                      await Whitelsdetails()
                      setTimeout(() => {
                        setShow(false)
                        setIsloading(false)
                        window.location.href = "/dashboard"
                      }, 1000);
                    }
                    else {
                      setIsloading(false)
                    }

                  } else {
                    toast.error(ToastApi.RegistrationErrorToast);
                    setIsloading(false)
                  }

                }
                else {
                  toast.error(ToastApi.SomeThingWentWorng);
                  setIsloading(false)
                }

              })


            }
          }
        } catch (error) {
          console.error('Error:', error);
          toast.error(ToastApi.SomeThingWentWorng);
          setIsloading(false)
        }
      } else {
        console.error('Invalid number');
        setIsloading(false)
      }
    } catch (error) {
      // Handle the outer catch block, if needed
      console.log("error", error);
      setIsloading(false)
    }
  }

  const approvedusd = async (address) => {
    try {
      const allowance = await DUSD_Contract_address.methods.allowance(connectedWallet, config.DEMO_CORE_PLAN_CONTRACT_ADDRESS).call();
      const registerationFeeAmount = await web3js.utils.toWei("15", "ether");
      const approvedAmountString = allowance.toString();
      let allowancefromwei = await web3js.utils.fromWei(allowance, 'ether')

      const gasPriceWei = web3js.utils.toWei('20', 'gwei');

      if (allowancefromwei >= registerationFeeAmount) {
        return true
      } else {
        const ApproveResult = await DUSD_Contract_address.methods.approve(config.DEMO_CORE_PLAN_CONTRACT_ADDRESS, registerationFeeAmount)
          .send({
            from: connectedWallet,
            gasPrice: gasPriceWei,
          });

        if (ApproveResult) {
          return true
        } else {
          return false
        }

      }
    } catch (error) {
      console.log('error', error)

    }
  }

  const switchNetwork = async () => {
    try {
      // Switch network
      await window.ethereum.request({
        method: Window_Ethereum_Config_Api.wallet_switchEthereumChain,
        params: [{ chainId: config.DCXCHAIN_HEX_ID }] // Replace '0x1' with the chain ID you want to switch to
      });
    } catch (error) {

    }
  }

  const handleconfirm = async () => {
    try {


      if (joinType === "Internal") {
        // waitinf list user
        setIsloading(true)
        if (influAddress && joinType) {

          const savedetails = {
            userName: username,
            userAddress: influAddress,
            CycleType: joinType,
            InfluencerId: "Not-Done"
          }
          const response = await UserWhitelistprocess(savedetails)

          if (response) {
            toast.success(ToastApi.SuccessSubmit)
            Onclose()
            setIsloading(false)
            await Whitelsdetails()
            setTimeout(() => {
              setShow(false)
              window.location.href = "/dashboard"
            }, 1000);
          }
        }

      }
      else if (joinType === "External") {
        // Register Opreator Funtionality


        await RegistorForExternal(influAddress);

      }
      else {
        setSelecterror("Select the options required")
        return
      }

    } catch (error) {
      console.log("Error", error);
    }
  }

  // function isUrlValid(string) {
  //   try {
  //     var url = new URL(string);
  //     console.log("url", url);
  //     return true;
  //   } catch (err) {
  //     return false;
  //   }
  // }


  function formatUrl(string, type) {
    if (string !== "N/A") {

      if (/^(http(s)?:\/\/)/i.test(string)) {
        return string;
      } else if (/^(www\.)/i.test(string)) {
        return `https://${string}`;
      } else {
        switch (type) {
          case 'Telegram':
            return `https://t.me/${string}`;
          case 'Discord':
            return `https://www.discord.com/${string}`;
          case 'Xtwitter':
            return `https://x.com/${string}`;
          case 'Youtube':
            return `https://www.youtube.com/${string}`;
          default:
            return string; // Or handle unknown types as needed
        }
      }
    } else {
      return string;
    }
  }


  function contactType(data) {
    try {
      let typecheck = data[0]
      if (typecheck.contacttype === "Whatsapp") {
        return (
          <>
            <FaWhatsapp />  {typecheck.contactno}
          </>
        );
      } else if (typecheck.contacttype === "Telegram") {
        return (
          <>
            <FaTelegramPlane /> {typecheck.contactno}
          </>
        );
      } else {
        return (
          <>
            <AiOutlineWechat /> {typecheck.contactno}
          </>
        );
      }
    } catch (error) {
      return error.toString();
    }
  }

  function copyToClipboard(text) {
    navigator.clipboard.writeText(text).then(() => {
      toast.success("Link copied to clipboard", { autoClose: 2000 });
    }).catch(err => {
      console.error('Failed to copy: ', err);
    });
  }

  async function deleteaction(ID) {
    try {
      const influencerId = ID;

      const response = await InfluencerDeletecall(influencerId);
      await Whitelsdetails();

    } catch (error) {
      console.log('error', error)
    }
  }

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className='list-request'>
      <div className='container withdraw-content'>
        <div className='row'>
          <div className='col'>
            <h1 className='py-3' style={{ color: "rgb(8, 29, 68)" }}>Whitelist Request</h1>

            <div className='d-flex justify-content-end py-4'>
              <div className=''>
                {connectedWallet
                  ? (<button class="nav-link wallet-address" >Wallet Address :  {sliceAddress(connectedWallet)}</button>)
                  : (<button class="nav-link" onClick={connectWallet}><i
                    class="bi bi-box-arrow-in-right" ></i> <span class="dashboard-text-1 p-2 wallet"> Connect Wallet</span>
                  </button>)}

              </div>
            </div>

            <table class="request-table">

              <tr>
                <th>User name</th>
                <th>user address</th>
                <th>Contact</th>
                <th>Social Network</th>
                <th>PropelX Position</th>
                <th>PropelX Actions</th>
                <th>Date</th>
              </tr>
              <tbody>
                {userDetails && userDetails.length > 0
                  ? (
                    userDetails.map((value, ind) => (
                      <>
                        <tr key={ind}>
                          <td data-td="username">
                            {value.name}
                          </td>
                          <td data-td="useraddress">
                            {sliceAddress(value.address)}
                          </td>

                          <td data-td="useraddress">
                            {contactType(value.contactType)}
                          </td>

                          <td data-td="socialnetwork">
                            {/* <!--Media Button trigger modal --> */}
                            <button type="button" class="btn request-table-btn" data-bs-toggle="modal" data-bs-target="#socialmedia" onClick={() => { SocialLinksfn(value.socialLink) }}>
                              SocialMedia
                            </button>
                          </td>
                          <td data-td="propelxposition">
                            {/* <!--Position Button trigger modal --> */}

                            <button type="button" class="btn request-table-btn" onClick={() => {
                              AddressGet(value.address, value._id)
                              setShow(true)
                            }}>
                              Join Now
                            </button>
                          </td>
                          <td data-td="propelxposition">
                            {/* <!--Position Button trigger modal --> */}
                            <button style={{ background: "rgb(155 33 33)", color: "white" }} type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target={`#staticBackdrop-${ind}`}>
                              Delete
                            </button>

                            {/* Modal */}
                            <div className="modal fade" id={`staticBackdrop-${ind}`} data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby={`staticBackdropLabel-${ind}`} aria-hidden="true">
                              <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content">
                                  <div class="modal-header border-0">
                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                  </div>
                                  <div className="modal-body">
                                    <h4 className=''>Are you sure do you want to delete ?</h4>
                                  </div>
                                  <div class="modal-footer border-0 justify-content-center pb-3">
                                    <Button type="button" onClick={() => deleteaction(value._id)} class="btn btn-primary px-3" data-bs-dismiss="modal">Yes</Button>
                                    <button type="button" class="btn btn-danger px-3" data-bs-dismiss="modal">No</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td data-td="useraddress">
                            {formatDate(new Date(value.date))}
                          </td>
                        </tr>
                      </>
                    )))
                  : (
                    <>
                      <tr>
                        <td colSpan="7">Record Not Found</td>
                      </tr>
                    </>)}

              </tbody>
            </table>

            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              pageCount={totalPages}
              onPageChange={changePage}
              containerClassName={"navigationButtons"}
              previousLinkClassName={"previousButton"}
              nextLinkClassName={"nextButton"}
              disabledClassName={"navigationDisabled"}
              activeClassName={"navigationActive"}
            />



            {/* <!--SocialMedia Modal --> */}
            <div class="modal fade" id="socialmedia" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-header text-center">
                    <h1 class="modal-title fs-5 ms-auto" id="staticBackdropLabel" style={{ color: "rgb(8, 29, 68)" }}>Media Links</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body">
                    <div className='container'>
                      <div className='row'>
                        <div className='col'>
                          <table className='request-table'>
                            <tr>
                              <th>Media</th>
                              <th>subscribers Count</th>
                              <th>Links</th>
                            </tr>
                            <tr>
                              <td>Telegram</td>
                              <td>{socialnet.TelegramCount}</td>
                              <td>
                                {(formatUrl(socialnet.Telegram, "Telegram") === "N/A") ? "N/A"
                                  : <> <a href={formatUrl(socialnet.Telegram, "Telegram")} target='_blank' className='media-link me-1'>link</a>
                                    <button className='border-0 bg-transparent' onClick={() => copyToClipboard(formatUrl(socialnet.Telegram, "Telegram"))}> <FaCopy /></button>
                                    </>}
                              </td>
                            </tr>
                            <tr>
                              <td>Discord</td>
                              <td>{socialnet.DiscortMemCount}</td>
                              <td>
                                {(formatUrl(socialnet.Discord, "Discord") === "N/A") ? "N/A"
                                    :<> <a href={formatUrl(socialnet.Discord, "Discord")} target='_blank' className='media-link me-1'>link</a>
                                     <button className='border-0 bg-transparent' onClick={() => copyToClipboard(formatUrl(socialnet.Discord, "Discord"))}><FaCopy /></button>
                                    </>}
                              </td>
                            </tr>
                            <tr>
                              <td>X</td>
                              <td>{socialnet.XfollowCount}</td>
                              <td>
                                {(formatUrl(socialnet.Xtwitter, "Xtwitter") === "N/A") ? "N/A" 
                                    : <> <a href={formatUrl(socialnet.Xtwitter, "Xtwitter")} target='_blank' className='media-link me-1'>link</a>
                                <button className='border-0 bg-transparent' onClick={() => copyToClipboard(formatUrl(socialnet.Xtwitter, "Xtwitter"))}><FaCopy /></button>
                                     </>}
                              </td>
                            </tr>
                            <tr>
                              <td>Youtube</td>
                              <td>{socialnet.YoutubeCount}</td>
                              <td>
                                { formatUrl(socialnet.Youtube, "Youtube") === "N/A" ? "N/A" 
                                   : <><a href={formatUrl(socialnet.Youtube, "Youtube")} target='_blank' className='media-link me-1'>link</a>
                                <button className='border-0 bg-transparent' onClick={() => copyToClipboard(formatUrl(socialnet.Youtube, "Youtube"))}><FaCopy /></button>
                                   </>}
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>



            {/* <!--PropelxPosition Modal --> */}
            {/* <div class="modal fade" id="joinnow" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-header text-center">
                    <h1 class="modal-title fs-5 ms-auto" id="staticBackdropLabel">PropelxPosition</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={Onclose}></button>
                  </div>
                  <div class="modal-body">
                    <div className='py-3'>
                      <span className='address-css'>Address</span>-{influAddress}
                    </div>
                    <div className='py-3 d-flex justify-content-center'>
                      <select class="form-select w-50" aria-label="Default select example" value={joinType} onChange={(e) => { SelectChange(e) }}>
                        <option value="" selected disabled>Select Line</option>
                        <option value="Internal">Internal</option>
                        <option value="External">External</option>
                      </select>
                    </div>
                    <span className='select-error'>{selecterror}</span>
                  </div>
                  <div class="modal-footer justify-content-center">
                    {
                      isloading
                        ? (<button type="button" class="btn request-table-btn" data-bs-dismiss="false">
                          <div class="spinner-border text-light" role="status">
                            <span class="sr-only">Loading...</span>
                          </div></button>)
                        : (<button type="button" class="btn request-table-btn" onClick={handleconfirm}>Confirm</button>)
                    }
                    <button type="button" class="btn request-table-btn" data-bs-dismiss="false" onClick={handleconfirm}>Confirm</button>
                  </div>
                </div>
              </div>
            </div> */}


            <Modal className='position-modal'
              show={show}
              onHide={Onclose}
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header closeButton className='justify-content-center d-flex flex-column-reverse'>
                <Modal.Title className='text-center'>PropelxPosition</Modal.Title>
              </Modal.Header>
              <Modal.Body className='text-center'>
                <div className='py-3'>
                  <span className='address-css'>Address</span>-{influAddress}
                </div>
                <div className='py-3 d-flex justify-content-center'>
                  <select class="form-select w-50" aria-label="Default select example" value={joinType} onChange={(e) => { SelectChange(e) }}>
                    <option value="" selected disabled>Select Line</option>
                    <option value="Internal">Internal</option>
                    <option value="External">External</option>
                  </select>
                </div>
                <span className='select-error'>{selecterror}</span>
              </Modal.Body>
              <Modal.Footer className='d-flex justify-content-center'>
                {
                  isloading
                    ? (<button type="button" class="btn request-table-btn" data-bs-dismiss="false">
                      <div class="spinner-border text-light" role="status">
                        <span class="sr-only">Loading...</span>
                      </div></button>)
                    : (<button type="button" class="btn request-table-btn" onClick={handleconfirm}>Confirm</button>)
                }
              </Modal.Footer>
            </Modal>

          </div>
        </div>
      </div>
    </div>
  )
}

export default Whitelistrequest