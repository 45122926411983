import React, { useState, useEffect } from 'react';
import Web3 from 'web3';
import DUSDtokenAbi from './ABI/DUSDtokenAbi.json';
import Plan1Abi from './ABI/Plan1Abi.json';
import DemoCorePlanAbi from './ABI/DemoCorePlan.json';
import ToastApi from '../config/toastApi';
import Window_Ethereum_Config_Api from '../config/windowEthereumConfig';
import config from '../config/config';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getAllRewardUsers, withdrawTransactionDetails, getAllwithdrawTransactionDetails } from './api/authapis';
import PendingTransaction from './PendingTransaction';
import ReactPaginate from 'react-paginate';
import moment from 'moment';
import jQuery from 'jquery';
import $ from 'jquery';

function Withdraw() {

    const [connectedWallet, setConnectedWallet] = useState(null);
    const [dchainid, setDchainid] = useState(null)
    const [isNetworkToastShown, setIsNetworkToastShown] = useState(false);
    const [toAddressForReward, setToAddressForReward] = useState();
    const [toAmountForReward, setToAmountForReward] = useState();
    const [adminWalletAddress, setAdminWalletAddress] = useState();
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState();
    const [validUserToClaim, setValidUserToClaim] = useState([]);
    const [visibleItems, setVisibleItems] = useState(config.REGISTER_HISTORY_SEE_MORE_LESS);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [disableClaimButton, setDisableClaimButton] = useState(false);
    const toastCheck = React.useRef(null);
    const [pickUserId, setPickUserId] = useState();
    const [rankingStatus, setRankingStatus] = useState();

    var web3js = '';

    if (window.ethereum) {
       if (connectedWallet != null && dchainid != config.DCXCHAIN_HEX_ID) {
            web3js = new Web3(new Web3.providers.HttpProvider(config.YOUR_NODE_RPC_URL));
        } else if (connectedWallet == null) {
            web3js = new Web3(new Web3.providers.HttpProvider(config.YOUR_NODE_RPC_URL));
        } else {
             web3js = new Web3(window.ethereum);
        }
        // web3js = new Web3(window.ethereum);
        // web3js = new Web3(new Web3.providers.HttpProvider(config.YOUR_NODE_RPC_URL));
    } else {
        web3js = new Web3(new Web3.providers.HttpProvider(config.YOUR_NODE_RPC_URL));
    }
    const DemoCorePlanContract = new web3js.eth.Contract(DemoCorePlanAbi, config.DEMO_CORE_PLAN_CONTRACT_ADDRESS);
    const DUSD_Contract_address = new web3js.eth.Contract(DUSDtokenAbi, config.DUSD_TOKEN_ADDRESS);


    const handleNetworkChange = async (chainId) => {
        try {
            if (chainId === config.DCXCHAIN_HEX_ID) {
                await window.ethereum.request({ method: Window_Ethereum_Config_Api.eth_requestAccounts });
                checkConnectedWallet();
                setIsNetworkToastShown(false); // Reset the flag when the network is correct
            }
        } catch (error) {
            console.error('Error handling network change:', error);
        }
    };

    const addCustomNetwork = async () => {
        try {
            await window.ethereum.request({
              method: Window_Ethereum_Config_Api.wallet_switchEthereumChain,
              params: [{ chainId: config.DCXCHAIN_HEX_ID }]
            });
            toast.success(ToastApi.AddNetworkSuccessToast);
          } catch (switchError) {
            if (switchError.code === 4902) {
              try {
                await window.ethereum.request({
                  method: Window_Ethereum_Config_Api.wallet_addEthereumChain,
                  params: [
                    {
                      chainId: config.DCXCHAIN_HEX_ID,
                      chainName: config.DCX_NETWORK_NAME,
                      nativeCurrency: {
                        name: config.DCX_NETWORK_NAME,
                        symbol: config.DCX_SYMBOL,
                        decimals: config.DCX_DECIMAL,
                      },
                      rpcUrls: [config.DCXRPC_URL],
                      blockExplorerUrls: [config.DCX_BLOCKEXPLORER],
                    },
                  ],
                });
                toast.success(ToastApi.AddNetworkSuccessToast);
              } catch (addError) {
                console.log("error", addError);
              }
            }
        }
    };

    const checkConnectedWallet = async () => {
        try {
            if (window.ethereum) {
                const accounts = await window.ethereum.request({ method: Window_Ethereum_Config_Api.eth_accounts });
                let checkSumMetamaskAddress = web3js.utils.toChecksumAddress(accounts?.[0]);

                setConnectedWallet(checkSumMetamaskAddress || null);

                const chainId = await window.ethereum.request({ method: Window_Ethereum_Config_Api.eth_chainId });
                setDchainid(chainId)

            }
            else {
                console.log("Metamask Not install");
            }

        } catch (error) {
            console.error('Error checking connected wallet:', error);
        }
    };

    const connectWallet = async () => {
        try {
            if (window.ethereum) {
                const chainId = await window.ethereum.request({ method: Window_Ethereum_Config_Api.eth_chainId });

                if (chainId !== config.DCXCHAIN_HEX_ID) {
                    await addCustomNetwork();
                } else {
                    await window.ethereum.request({ method: Window_Ethereum_Config_Api.eth_requestAccounts });
                    checkConnectedWallet();
                }
            } 
            else {
                toast.warn(ToastApi.PleaseInstallMetamsk)
                setTimeout(() => {
                    handleInstallMetaMask('https://metamask.io/download.html')
                  }, 1000);
            }
        } catch (error) {
            toast.error(ToastApi.WalletConnectionError);
        }
    };

    const handleInstallMetaMask = async (url) => {
        const newWindow = window.open(url, '_blank');
        if (newWindow) {
          newWindow.focus()
        }
      };

    const sliceAddress = (address, startLength = 6, endLength = 6) => {
        try {
            if (!address) return '';

            const prefix = address.slice(0, startLength);
            const suffix = address.slice(-endLength);

            return `${prefix}...${suffix}`;
        } catch (error) {

        }
    };


    useEffect(() => {

        // checkConnectedWallet();
        try {


            if (window.ethereum) {

                window.ethereum.on(Window_Ethereum_Config_Api.chainChanged, async (chainId) => {
                    try {
                        if (chainId == config.DCXCHAIN_HEX_ID) {
                            await window.ethereum.request({ method: Window_Ethereum_Config_Api.eth_requestAccounts });
                            await checkConnectedWallet();
                        } else {
                            handleNetworkChange(config.DCXCHAIN_HEX_ID);
                            if (!isNetworkToastShown) {
                                if (!toast.isActive(toastCheck.current)) {
                                    if(toastCheck.current === null) {
                                        toastCheck.current = toast.error(ToastApi.NetworkChangeToast);
                                        setIsNetworkToastShown(true);
                                    }
                                }
                            }
                        }
                    } catch (error) {
                        console.error('Error setting up event listener:', error);
                    }
                });

            }

            const eventAccountWatch = async () => {
                if (window.ethereum) {
                    const accounts = await window.ethereum.request({ method: Window_Ethereum_Config_Api.eth_accounts });

                    if (accounts.length === 1) {
                        let checkSumMetamaskAddress = web3js.utils.toChecksumAddress(accounts[0]);
                        setConnectedWallet(checkSumMetamaskAddress);

                        const chainId = await window.ethereum.request({ method: Window_Ethereum_Config_Api.eth_chainId });
                        setDchainid(chainId)

                    } else if (accounts.length > 1) {
                        if (!toast.isActive(toastCheck.current)) {
                            if (toastCheck.current === null) {
                                toastCheck.current = toast.warning(ToastApi.ConnectAnyOneAccount)
                            }
                        }
                    }
                }

                // else if (accounts.length == 0) {
                //     toast.warning(ToastApi.ConnectYourWallet);
                // }
            }
            eventAccountWatch();
        } catch (error) {

        }
    }, [isNetworkToastShown]);


    function formatDate(dateObject) {
        const year = dateObject.getUTCFullYear();
        const month = String(dateObject.getUTCMonth() + 1).padStart(2, '0');
        const day = String(dateObject.getUTCDate()).padStart(2, '0');
        const hours = String(dateObject.getUTCHours()).padStart(2, '0');
        const minutes = String(dateObject.getUTCMinutes()).padStart(2, '0');
        const seconds = String(dateObject.getUTCSeconds()).padStart(2, '0');
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }

    const foundTheRankingData = async () => {

        try {
            const getRankData = await getAllRewardUsers();
            if (getRankData) {

                const pushRankDataInArray = [];
                const levelOfRank = getRankData.data.data;
                levelOfRank.forEach(element => {

                    const objectValue = {};

                    objectValue['amount'] = element.PerCentage_20_Amount;
                    objectValue['ranking'] = element.Rank_Status;
                    objectValue['toAddress'] = element.User_Address;
                    objectValue['id'] = element._id;
                    objectValue['User_Id'] = element.User_Id;
                    objectValue['Number_Of_Reward_count'] = element.Number_Of_Reward_count;


                    if (element.Transaction_Expired_Time == null) {
                        const dateObject = new Date();
                        // const year = dateObject.getUTCFullYear();
                        // const month = String(dateObject.getUTCMonth() + 1).padStart(2, '0');
                        // const day = String(dateObject.getUTCDate()).padStart(2, '0');
                        // const hours = String(dateObject.getUTCHours()).padStart(2, '0');
                        // const minutes = String(dateObject.getUTCMinutes()).padStart(2, '0');
                        // const seconds = String(dateObject.getUTCSeconds()).padStart(2, '0');
                        // const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
                        // const remainingTime = dateObject.getTime() - new Date().getTime();
                        // const remainingTimeString = remainingTime > 0 ? new Date(remainingTime).toISOString().substr(11, 8) : '00:00:00';
                        // objectValue['formattedDate'] = formattedDate;
                        // objectValue['remainingTimeString'] = remainingTimeString;
                        // objectValue['transactionDate'] = dateObject.toUTCString();
                        // console.log('dateObject--hfgh---', element.Transaction_Expired_Time);
                    } else {
                        const dateObject = new Date(element.Transaction_Expired_Time);
                        const createdateObj = new Date(element.claimsendDate);
                        const formattedDate = formatDate(dateObject);
                        const CreDate = formatDate(createdateObj);
                        const remainingTime = dateObject.getTime() - new Date().getTime();
                        const remainingTimeString = remainingTime > 0 ? new Date(remainingTime).toISOString().substr(11, 8) : '00:00:00';
                        objectValue['formattedDate'] = formattedDate;
                        objectValue['TranxExpTime'] = element.Transaction_Expired_Time;
                        objectValue['remainingTimeString'] = remainingTimeString;
                        objectValue['transactionDate'] = dateObject.toUTCString();
                        objectValue['RewardSendDate'] = CreDate;
                        objectValue['create_date'] = element.Create_at;
                        
                    }


                    pushRankDataInArray.push(objectValue);
                });

                setValidUserToClaim(pushRankDataInArray);

            } else {
                // return '';
            }
        } catch (error) {

        }
    }

    useEffect(() => {
        foundTheRankingData();


        // if (toAddressForReward == undefined && toAmountForReward == undefined) {
        //     setErrorMessage('Input requied');
        // }

        // Set interval to call the function every 5 seconds
        const intervalId = setInterval(foundTheRankingData, 5000);

        // Cleanup function to clear interval when component unmounts
        return () => clearInterval(intervalId);
    }, []);


    function calculateRemainingTime(remainingTimeString) {
        const remainingTime = new Date(remainingTimeString);
        const currentDate = new Date();

        // Check if remainingTime is a valid Date
        if (isNaN(remainingTime.getTime())) {
            return '0-0-0';
        }

        const differenceInMilliseconds = remainingTime - currentDate;

        if (differenceInMilliseconds <= 0) {
            return 'Claim now';
        } else {
            const totalSeconds = Math.floor(differenceInMilliseconds / 1000);
            const totalMinutes = Math.floor(totalSeconds / 60);
            const totalHours = Math.floor(totalMinutes / 60);
            const totalDays = Math.floor(totalHours / 24);
            const totalMonths = Math.floor(totalDays / 30); // Approximation
            const totalYears = Math.floor(totalDays / 365); // Approximation

            const remainingHours = totalHours % 24;
            const remainingMinutes = totalMinutes % 60;
            const remainingSeconds = totalSeconds % 60;
            const remainingDays = totalDays % 30; // Approximation

            let result = '';

            if (totalYears > 0) {
                result += `${totalYears}y `;
            }
            if (totalMonths > 0) {
                result += `${totalMonths}mo `;
            }
            if (remainingDays > 0) {
                result += `${remainingDays}d `;
            }
            if (remainingHours > 0) {
                result += `${remainingHours}h `;
            }
            if (remainingMinutes > 0) {
                result += `${remainingMinutes}m `;
            }
            if (remainingSeconds > 0) {
                result += `${remainingSeconds}s `;
            }

            // Trim trailing space
            result = result.trim();

            return result
        }
    }



    useEffect(() => {

        calculateRemainingTime()

        // // Update remaining time every 5 seconds
        // const interval = setInterval(updateRemainingTime, 1000);

        // // Clean up interval on component unmount
        // return () => clearInterval(interval);

    }, []);

    const pickIdFromTheValidUser = (address, amount, adminAddress, userId, Rank) => {

        window.scrollTo(0, 0)
        if (address && address) {

            setErrorMessage('');
            setToAddressForReward(address);
            setToAmountForReward(amount);
            setAdminWalletAddress(adminAddress);
            setPickUserId(userId);
            setRankingStatus(Rank);
        }
        if (config.D_REWARD_STORED_ADDRESS != connectedWallet) {
            // if (!toast.isActive(toastCheck.current)) {
            //     toastCheck.current = toast.warning(`Please conncet with address ${sliceAddress(config.D_REWARD_STORED_ADDRESS)}`);
            //     setIsNetworkToastShown(true);
            // }
            toast.warning(`Please conncet with address ${sliceAddress(config.D_REWARD_STORED_ADDRESS)}`);
        }


    }



    const tokenTransfer = async () => {
        try {
            if (config.D_REWARD_STORED_ADDRESS != connectedWallet) {
                return toast.warning(`Please conncet with address ${sliceAddress(config.D_REWARD_STORED_ADDRESS)}`);
            }
            setLoading(true);
            const gasPriceWei = web3js.utils.toWei('20', 'gwei'); // Adjust the gas price as needed 

            const buyAmount = web3js.utils.toWei(toAmountForReward, 'ether');
            const resultOfTransfer = await DUSD_Contract_address.methods.transfer(toAddressForReward, buyAmount).send({
                from: connectedWallet,
                gasPrice: gasPriceWei,
                // gas: 21000
            });


            const currentDate = new Date();
            const buttonExipredTime = new Date(currentDate.getTime() + 10 * 60 * 1000); // Adding 5 minutes (5 * 60 * 1000 milliseconds)
            var sendJsonData = {
                pickUserId,
                rankingStatus,
                resultOfTransfer,
                buttonExipredTime
            }


            const tokenTransaction = await withdrawTransactionDetails(sendJsonData);

            if (tokenTransaction) {
                setLoading(false);
                setDisableClaimButton(true); // Disable the claim button
                toast.success(`Reward Sent Successfully`)
                setTimeout(() => {
                    setDisableClaimButton(false); // Enable the claim button after 1 minute
                }, 2000); // 1 minute in milliseconds
                foundTheRankingData()
                setTimeout(() => {
                   window.location.reload();
                }, 1000);
            }
    
        } catch (error) {
            setLoading(false);
        }
    };

    const [overviewTansactionPage, setoverviewTansactionPage] = useState(0);

    const TransactionPerPage = config.TRANSACTION_INDEX_PAGE_NUM;

    const numberOfTansactionDetails = overviewTansactionPage * TransactionPerPage;

    const totalPages = Math.ceil(validUserToClaim.length / TransactionPerPage);

    const changePage = ({ selected }) => {

        setoverviewTansactionPage(selected);
    };

    const startIndex = overviewTansactionPage * TransactionPerPage;

    const currentDate = new Date();

    useEffect(() => {

        $(".scroll-top").click(function() {
            $('html, body').animate({
                scrollTop: $("#myDiv").offset().top
            });
        });

      },[]);

    

    return (
        <div>
            <div className='container withdraw-content'>
                <h1 className='py-5' style={{ color: "rgb(8 29 68)" }} id='myDiv'>Reward Distribution Section</h1>
                <div className='d-flex justify-content-center'>
                    <div className=''>
                        {connectedWallet ? (
                            <div class="btn33 fs-6 wallet-address"> Connected Wallet: {sliceAddress(connectedWallet)}</div> 
                        ) : (
                            <button class="nav-link" onClick={connectWallet}><i
                                class="bi bi-box-arrow-in-right" ></i> <span class="dashboard-text-1 p-2 wallet"> Connect Wallet</span></button>

                        )}
                    </div>
                </div>


                
                <div className='row pt-5'>
                    {connectedWallet != config.D_REWARD_STORED_ADDRESS ? (
                        <div className='col'>
                            <div class="form-floating mb-3">
                                <input type="name" class="form-control" id="fromInput" placeholder="From" disabled />
                                <label for="fromInput">From</label>

                            </div>

                        </div>
                    ) : (
                        <>
                            <div className='col'>
                                <div class="form-floating mb-3">
                                    <input type="name" class="form-control" id="fromInput" placeholder="From" value={connectedWallet} disabled />
                                    <label for="fromInput">From</label>

                                </div>

                            </div>
                        </>
                    )}
                    {!toAddressForReward && !toAmountForReward ? (
                        <>
                            <div className='col'>
                                <div class="form-floating mb-3">
                                    <input type="name" class="form-control" id="toInput" placeholder="To" value={toAddressForReward} disabled />
                                    <label for="toInput">To</label>
                                </div>

                            </div>
                        </>
                    ) : (
                        <>
                            <div className='col'>
                                <div class="form-floating mb-3">
                                    <input type="name" class="form-control" id="toInput" placeholder="To" value={toAddressForReward} disabled />
                                    <label for="toInput">To</label>
                                </div>
                                <span style={{ color: "red" }}>
                                    {errorMessage}
                                </span>
                            </div>
                        </>
                    )}

                </div>
                <div className='row pt-5'>
                    {!toAddressForReward && !toAmountForReward ? (
                        <>
                            <div className='col-lg-6 mx-auto'>
                                <div class="form-floating mb-3">
                                    <input type="name" class="form-control" id="amoumtInput" placeholder="Amount" value={toAmountForReward} disabled />
                                    <label for="amountInput">Amount</label>
                                </div>

                            </div>
                        </>
                    ) : (
                        <>
                            <div className='col-lg-6 mx-auto'>
                                <div class="form-floating mb-3">
                                    <input type="name" class="form-control" id="amoumtInput" placeholder="Amount" value={toAmountForReward} disabled />
                                    <label for="amountInput">Amount</label>
                                </div>
                                <span style={{ color: "red" }}>
                                    {errorMessage}
                                </span>
                            </div>
                        </>
                    )}

                    {!toAddressForReward && !toAmountForReward && !adminWalletAddress ? (
                        <div className='pt-5'>
                            <a >
                                <button type="button" class="btn w-25" style={{ background: "rgb(8 29 68)", color: "white" }} disabled>
                                    CLICK BELOW LIST
                                </button>
                            </a>
                        </div>
                    ) : (
                        <div className='pt-5'>
                            <a>
                                <button type="button" class="btn w-25" style={{ background: "rgb(8 29 68)", color: "white" }} onClick={tokenTransfer} disabled={loading}>
                                    {loading ? 'Loading...' : 'CLICK TO SEND'}
                                </button>
                            </a>
                        </div>
                    )}

                </div>
            


                <div className='row pt-5'>
                    <div className='col'>
                        <div class="container">
                            <h1 className='pb-5' style={{ color: "rgb(8 29 68)" }}>Send Rewards</h1>
                            <table class="reward-table">
                                <tr>
                                    <th>SI.no</th>
                                    <th>To Address</th>
                                    <th>ID</th>
                                    <th>Rank</th>
                                    <th>Amount</th>
                                    <th>Claim</th>
                                    <th>Count of Reward</th>
                                    <th>Last Reward Date</th>
                                </tr>
                                {validUserToClaim.length === 0 ? (
                                    <>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td>Record Not Found</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </>
                                ) : (
                                    validUserToClaim.slice(numberOfTansactionDetails, numberOfTansactionDetails + TransactionPerPage)?.map((item, index) => {
                                    // validUserToClaim.sort((a, b) => new Date(b.RewardSendDate) - new Date(a.RewardSendDate)).slice(numberOfTansactionDetails, numberOfTansactionDetails + TransactionPerPage)?.map((item, index) => {


                                        const transactionIndex = startIndex + index + 1;

                                        return (
                                            <tr key={index}>
                                                <td data-th="Supplier Code">
                                                    {transactionIndex}
                                                </td>
                                                <td data-th="Supplier Name">
                                                    {sliceAddress(item.toAddress)}
                                                </td>
                                                <td data-th="Invoice Number">
                                                    {item.User_Id}
                                                </td>
                                                <td data-th="Invoice Number">
                                                    {item.ranking}
                                                </td>
                                                <td data-th="Invoice Number">
                                                    {Number(item.amount).toFixed(2)}
                                                </td>
                                                <td data-th="Invoice Date">
                                                    <div>
                                                        <div> 
                                                            {item.formattedDate ? (
                                                                new Date(item.TranxExpTime) < currentDate ? (
                                                                <button className="scroll-top btn w-30"  onClick={() => { pickIdFromTheValidUser(item.toAddress, item.amount, config.D_REWARD_STORED_ADDRESS, item.User_Id, item.ranking) }} style={{ background: "rgb(8 29 68)", color: "white" }} disabled={loading || disableClaimButton}>
                                                                    CLAIM ACTIVATED
                                                                </button>
                                                                ) : (
                                                                    <button className="btn w-30" style={{ background: "rgb(8 29 68)", color: "white" }} disabled>
                                                                        CLAIM DE-ACTIVATED
                                                                    </button>
                                                                )
                                                            ) : (
                                                                <button className="scroll-top btn w-30"  onClick={() => { pickIdFromTheValidUser(item.toAddress, item.amount, config.D_REWARD_STORED_ADDRESS, item.User_Id, item.ranking) }} style={{ background: "rgb(8 29 68)", color: "white" }} disabled={loading || disableClaimButton}>
                                                                    CLAIM ACTIVATED
                                                                </button>
                                                            )}
                                                        </div>

                                                        {/* <div>
                                                            {item.formattedDate && (
                                                                <>
                                                                    {new Date(item.formattedDate) < currentDate ? (
                                                                        <button onClick={() => { pickIdFromTheValidUser(item.toAddress, item.amount, config.D_REWARD_STORED_ADDRESS, item.User_Id, item.ranking) }} className="btn w-30" style={{ background: "#031228", color: "white" }} disabled={loading || disableClaimButton}>
                                                                            CLAIM ACTIVATED
                                                                        </button>
                                                                    ) : (
                                                                        <button className="btn w-30" style={{ background: "#031228", color: "white" }} disabled>
                                                                            CLAIM DE-ACTIVATED
                                                                        </button>
                                                                    )}
                                                                </>
                                                            )}
                                                            {item.formattedDate == undefined && (
                                                                <>

                                                                    <button onClick={() => { pickIdFromTheValidUser(item.toAddress, item.amount, config.D_REWARD_STORED_ADDRESS, item.User_Id, item.ranking) }} className="btn w-30" style={{ background: "#031228", color: "white" }} disabled={loading || disableClaimButton}>
                                                                        CLAIM ACTIVATED
                                                                    </button>


                                                                </>
                                                            )}
                                                        </div> */}





                                                    </div>


                                                </td>
                                                <td>{item.Number_Of_Reward_count}</td>
                                                <td data-th="Remaining Time">
                                                    {item.formattedDate !== undefined ? (
                                                        <>
                                                    {item.RewardSendDate} - {calculateRemainingTime(item.TranxExpTime)}
                                                        </>
                                                    ) : (
                                                        <>
                                                            <div>Claim The First Reward</div>
                                                        </>
                                                    )}
                                                </td>
                                            </tr>
                                        );
                                    })
                                )}



                            </table>
                            <div className='mx-auto py-5'>
                                <ReactPaginate
                                    previousLabel={"Previous"}
                                    nextLabel={"Next"}
                                    pageCount={totalPages}
                                    onPageChange={changePage}
                                    containerClassName={"navigationButtons"}
                                    previousLinkClassName={"previousButton"}
                                    nextLinkClassName={"nextButton"}
                                    disabledClassName={"navigationDisabled"}
                                    activeClassName={"navigationActive"}
                                />

                                {/* <nav aria-label="Page navigation example">
                                    <ul className="pagination justify-content-center">
                                        {validUserToClaim.length > config.REGISTER_HISTORY_SEE_MORE_LESS && (
                                            <li className="page-item" onClick={() => handlePageClick(currentPage - 1)}><a className="page-link">Previous</a></li>
                                        )}

                                        {pageNumbers.map((pageNumber) => (
                                            <li key={pageNumber} className={`page-item ${pageNumber === currentPage ? 'active' : ''}`} onClick={() => handlePageClick(pageNumber)}>
                                                <a className="page-link">{pageNumber}</a>
                                            </li>
                                        ))}

                                        {validUserToClaim.length > config.REGISTER_HISTORY_SEE_MORE_LESS && (
                                            <li className="page-item" onClick={() => handlePageClick(currentPage + 1)}><a className="page-link">Next</a></li>
                                        )}
                                    </ul>
                                </nav> */}
                            </div>
                        </div>
                    </div>
                </div>
                {/* <PendingTransaction /> */}
            </div>
        </div>
    )
}

export default Withdraw