let config = {}

    config = {
        walletConnectText : 'Please connect your wallet',
        walletConnectionError : 'Wallet connection cancelled',
        NetworkChangeToast : 'Change your network to D-CHAIN',
        AddNetworkSuccessToast : 'Custom network added successfully',
        WalletConnectionError : 'Wallet connection error',
        ReferralIdNotFound : 'Referral Id Not Found',
        UserAlreadyRegistered: "User Already Existed",
        UserNotRegistered: "User Not Existed",
        SomeThingWentWorng : "Transaction Rejected",
        SuccessfullyRegistered : 'Success fully Registered',
        RegistrationErrorToast : 'Transaction Failed',
        ConnectAnyOneAccount : 'Connect any one account',
        RegisterToLogin : 'Register To Login',
        SuccessfullyLogedIn : 'Successfully logged in',
        ConnectYourWallet : 'Connect Your Wallet',
        ReferralIdNotFound : "Referral Id Not Found",
        CopiedAddress : 'Address copied to clipboard',
        FailedToCopy : 'Failed to copy address to clipboard',
        ContractAddressCopied : 'Contract address Copied',
        RegisterLinkCopiedWithID : 'Link Copied',
        BuyPreviousLevel : "Buy previous level",
        SameUserIdOfWalletAddress: "Only Owner Can Buy",
        NextLevelPurchasedSuccess : " Level purchased Successfully",
        EnterNumberOnly: "Please enter numbers only.",
        PleaseInstallMetamsk:"Please Install Metamask",
        SuccessSubmit:"Successfully Submitted"
    }


export default config;
