
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import config from '../config/config';
import isEmpty from 'is-empty';
import Web3 from 'web3';
import ToastApi from '../config/toastApi';
import DemoCorePlanAbi from './ABI/DemoCorePlan.json';
import DUSDtokenAbi from './ABI/DUSDtokenAbi.json';
import Window_Ethereum_Config_Api from '../config/windowEthereumConfig';
import { toast } from 'react-toastify';
import { WhistlistWaitData, userRegister, ConfirmExternal, ConfirmImmediateExternal, Getuserexternal } from "./api/authapis"
import { Flag } from '@mui/icons-material';
import ReactPaginate from 'react-paginate';
import { FaCopy } from "react-icons/fa6";
import { FaTelegramPlane, FaWhatsapp } from "react-icons/fa";
import { AiOutlineWechat } from "react-icons/ai";


function Whitelistuser() {

    const [activeTab, setActiveTab] = useState('internal');
    const [InternalArr, setInternalArr] = useState([]);
    const [externalArr, setExternalArr] = useState([]);
    const [Internaldetails, setInternaldetails] = useState([])
    const [externaluser, setExternaluser] = useState([])

    function formatDate(dateObject) {
        const year = dateObject.getUTCFullYear();
        const month = String(dateObject.getUTCMonth() + 1).padStart(2, '0');
        const day = String(dateObject.getUTCDate()).padStart(2, '0');
        const hours = String(dateObject.getUTCHours()).padStart(2, '0');
        const minutes = String(dateObject.getUTCMinutes()).padStart(2, '0');
        const seconds = String(dateObject.getUTCSeconds()).padStart(2, '0');
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }

    const [overviewTansactionPage, setoverviewTansactionPage] = useState(0);

    const TransactionPerPage = config.TRANSACTION_INDEX_PAGE_NUM

    const numberOfTansactionDetails = overviewTansactionPage * TransactionPerPage;

    const totalPages = activeTab == "internal" ? Math.ceil(InternalArr.length / TransactionPerPage) : "";
    const changePage = ({ selected }) => {

        setoverviewTansactionPage(selected);
    };

    const startIndex = overviewTansactionPage * TransactionPerPage;


    //external paganiations
    const [overviewTansactionPage1, setoverviewTansactionPage1] = useState(0);

    const TransactionPerPage1 = config.TRANSACTION_INDEX_PAGE_NUM;

    const numberOfTansactionDetails1 = overviewTansactionPage1 * TransactionPerPage1;

    const totalPages1 = Math.ceil(externalArr.length / TransactionPerPage1);

    const changePage1 = ({ selected }) => {

        setoverviewTansactionPage1(selected);
    };

    const startIndex1 = overviewTansactionPage1 * TransactionPerPage1;


    const sliceAddress = (address, startLength = 6, endLength = 6) => {
        try {
            if (!address) return '';

            const prefix = address.slice(0, startLength);
            const suffix = address.slice(-endLength);

            return `${prefix}...${suffix}`;
        } catch (error) {

        }
    };


    const InfluencerDetails = async () => {
        try {
            const Response = await WhistlistWaitData();

            if (Response) {
                const PushWhitels = []

                const Whitelist = Response.data
                Whitelist.forEach(element => {
                    const Objectvalue = {}

                    if (element.Status === "Active" && element.CycleType === "Internal") {
                        Objectvalue['_id'] = element._id
                        Objectvalue['name'] = element.userName
                        Objectvalue['address'] = element.userAddress
                        Objectvalue['status'] = element.Status
                        Objectvalue['Id'] = element.InfluencerId
                        Objectvalue['date'] = element.date
                        Objectvalue['cycleline'] = element.CycleType
                        Objectvalue['userDetails'] = element.influencerDetails[0]

                        PushWhitels.push(Objectvalue)

                    } else {
                        console.log("user not here");
                    }
                });
                console.log('PushWhitels', PushWhitels)
                setInternalArr(PushWhitels)
            } else {
                toast.warn("something wrong data reponse")
            }

        } catch (error) {
            console.log("error", error);
        }
    }


    const ExternalDetails = async () => {
        try {
            const Response = await Getuserexternal();


            if (Response) {
                const PushWhitels = []

                const Whitelist = Response.data
                Whitelist.forEach(element => {
                    const Objectvalue = {}

                    if (element.CycleType === "External") {
                        Objectvalue['_id'] = element._id
                        Objectvalue['name'] = element.userName
                        Objectvalue['address'] = element.UserAddress
                        Objectvalue['Id'] = element.userid
                        Objectvalue['type'] = element.CycleType
                        Objectvalue['date'] = element.date
                        Objectvalue['userDetails'] = element.influencerDetails[0];

                        PushWhitels.push(Objectvalue)
                    } else {
                        console.log("user not here");
                    }
                });
                console.log('PushWhitels', PushWhitels)
                setExternalArr(PushWhitels)
            } else {
                toast.warn("something wrong data reponse")
            }

        } catch (error) {
            console.log("error", error);
        }
    }

    useEffect(() => {
        if (activeTab === 'internal') {
            InfluencerDetails();
        } else {
            ExternalDetails();
        }
    }, [activeTab]);



    const userdatas = async (userdatas) => {
        try {

            if (userdatas) {
                setInternaldetails(userdatas);
            }
        } catch (error) {

        }
    }

    const userdatasexternal = async (userdatas) => {
        try {

            if (userdatas) {
                setExternaluser(userdatas);
            }
        } catch (error) {

        }
    }


    function contactType(data) {
        try {
            let typecheck = data[0]
            if (typecheck.contacttype === "Whatsapp") {
                return (
                    <>
                        <FaWhatsapp />  {typecheck.contactno}
                    </>
                );
            } else if (typecheck.contacttype === "Telegram") {
                return (
                    <>
                        <FaTelegramPlane /> {typecheck.contactno}
                    </>
                );
            } else {
                return (
                    <>
                        <AiOutlineWechat /> {typecheck.contactno}
                    </>
                );
            }
        } catch (error) {
            return error.toString();
        }
    }

    const renderInternalTableRows = (data) => {
        if (!data || data.length === 0) {
            return (
                <tr>
                    <td colSpan="7">Data not found</td>
                </tr>
            );
        }

        return data.slice(numberOfTansactionDetails, numberOfTansactionDetails + TransactionPerPage).map((row, index) => (
            <tr key={index}>
                <td>{startIndex + index + 1}</td>
                <td>{row.name}</td>
                <td>{sliceAddress(row.address)}</td>
                <td>{row.Id}</td>
                <td>{row.cycleline}</td>
                <td>
                    {/* <!-- Button trigger modal --> */}
                    <button type="button" class="btn detail-btn" data-bs-toggle="modal" data-bs-target="#in-detail" onClick={() => userdatas(row.userDetails)}>
                        Details
                    </button>

                    {/* <!-- Modal --> */}
                    <div class="modal fade" id="in-detail" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="in-detailLabel" aria-hidden="true">
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-header border-0">
                                    <h1 class="modal-title fs-5" id="in-detailLabel">Internal Line</h1>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div class="modal-body">
                                    <table className='userdetails-table'>
                                        <tr>
                                            <th>Media</th>
                                            <th>subscribers Count</th>
                                            <th>Links</th>
                                        </tr>
                                        <tbody>
                                            <tr>
                                                <td>Contact</td>
                                                <td>-</td>
                                                <td>{contactType(Internaldetails.contactDetails)}</td>
                                            </tr>
                                            <tr>
                                                <td>Telegram</td>
                                                <td>{Internaldetails.TelegramSubcount}</td>
                                                <td>
                                                    {(formatUrl(Internaldetails.TelegramName, "Telegram") === "N/A") ? "N/A"
                                                        : <> <a href={formatUrl(Internaldetails.TelegramName, "Telegram")} target='_blank' className='media-link me-1'>link</a>
                                                            <button className='border-0 bg-transparent' onClick={() => copyToClipboard(formatUrl(Internaldetails.TelegramName, "Telegram"))}> <FaCopy /></button>
                                                        </>}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Discord</td>
                                                <td>{Internaldetails.DiscortMemCount}</td>
                                                <td>
                                                    {(formatUrl(Internaldetails.Discord, "Discord") === "N/A") ? "N/A"
                                                        : <> <a href={formatUrl(Internaldetails.Discord, "Discord")} target='_blank' className='media-link me-1'>link</a>
                                                            <button className='border-0 bg-transparent' onClick={() => copyToClipboard(formatUrl(Internaldetails.Discord, "Discord"))}><FaCopy /></button>
                                                        </>}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>X</td>
                                                <td>{Internaldetails.XfollowCount}</td>
                                                <td>
                                                    {(formatUrl(Internaldetails.Xtwitter, "Xtwitter") === "N/A") ? "N/A"
                                                        : <> <a href={formatUrl(Internaldetails.Xtwitter, "Xtwitter")} target='_blank' className='media-link me-1'>link</a>
                                                            <button className='border-0 bg-transparent' onClick={() => copyToClipboard(formatUrl(Internaldetails.Xtwitter, "Xtwitter"))}><FaCopy /></button>
                                                        </>}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Youtube</td>
                                                <td>{Internaldetails.YoutubeSubcount}</td>
                                                <td>
                                                    {formatUrl(Internaldetails.YoutubeName, "Youtube") === "N/A" ? "N/A"
                                                        : <><a href={formatUrl(Internaldetails.YoutubeName, "Youtube")} target='_blank' className='media-link me-1'>link</a>
                                                            <button className='border-0 bg-transparent' onClick={() => copyToClipboard(formatUrl(Internaldetails.Youtube, "Youtube"))}><FaCopy /></button>
                                                        </>}                                                  
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </td>
                <td>{formatDate(new Date(row.date))}</td>
            </tr>
        ));
    };

    const renderExternalTableRows = (data) => {
        if (!data || data.length === 0) {
            return (
                <tr>
                    <td colSpan="7">Data not found</td>
                </tr>
            );
        }

        return data.slice(numberOfTansactionDetails1, numberOfTansactionDetails1 + TransactionPerPage1).map((row, index) => (
            <tr key={index}>
                <td>{startIndex + index + 1}</td>
                <td>{row.name}</td>
                <td>{sliceAddress(row.address)}</td>
                <td>{row.Id}</td>
                <td>{row.type}</td>
                <td>
                    {/* <!-- Button trigger modal --> */}
                    <button type="button" class="btn detail-btn" data-bs-toggle="modal" data-bs-target="#ex-detail" onClick={() => { userdatasexternal(row.userDetails) }}>
                        Details
                    </button>

                    {/* <!-- Modal --> */}
                    <div class="modal fade" id="ex-detail" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="ex-detailLabel" aria-hidden="true">
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-header border-0">
                                    <h1 class="modal-title fs-5" id="ex-detailLabel">External Line</h1>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div class="modal-body">
                                    <table className='userdetails-table'>
                                        <tr>
                                            <th>Media</th>
                                            <th>subscribers Count</th>
                                            <th>Links</th>
                                        </tr>
                                        <tbody>
                                            <tr>
                                                <td>Contact</td>
                                                <td>-</td>
                                                <td>{contactType(externaluser.contactDetails)}</td>
                                            </tr>
                                            <tr>
                                                <td>Telegram</td>
                                                <td>{externaluser.TelegramSubcount}</td>
                                                <td>
                                                    {(formatUrl(externaluser.TelegramName, "Telegram") === "N/A") ? "N/A"
                                                        : <> <a href={formatUrl(externaluser.TelegramName, "Telegram")} target='_blank' className='media-link me-1'>link</a>
                                                            <button className='border-0 bg-transparent' onClick={() => copyToClipboard(formatUrl(externaluser.TelegramName, "Telegram"))}> <FaCopy /></button>
                                                        </>}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Discord</td>
                                                <td>{externaluser.DiscortMemCount}</td>
                                                <td>
                                                    {(formatUrl(externaluser.Discord, "Discord") === "N/A") ? "N/A"
                                                        : <> <a href={formatUrl(externaluser.Discord, "Discord")} target='_blank' className='media-link me-1'>link</a>
                                                            <button className='border-0 bg-transparent' onClick={() => copyToClipboard(formatUrl(externaluser.Discord, "Discord"))}><FaCopy /></button>
                                                        </>}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>X</td>
                                                <td>{externaluser.XfollowCount}</td>
                                                <td>
                                                    {(formatUrl(externaluser.Xtwitter, "Xtwitter") === "N/A") ? "N/A"
                                                        : <> <a href={formatUrl(externaluser.Xtwitter, "Xtwitter")} target='_blank' className='media-link me-1'>link</a>
                                                            <button className='border-0 bg-transparent' onClick={() => copyToClipboard(formatUrl(externaluser.Xtwitter, "Xtwitter"))}><FaCopy /></button>
                                                        </>}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Youtube</td>
                                                <td>{externaluser.YoutubeSubcount}</td>
                                                <td>
                                                    {formatUrl(externaluser.YoutubeName, "Youtube") === "N/A" ? "N/A"
                                                        : <><a href={formatUrl(externaluser.YoutubeName, "Youtube")} target='_blank' className='media-link me-1'>link</a>
                                                            <button className='border-0 bg-transparent' onClick={() => copyToClipboard(formatUrl(externaluser.Youtube, "Youtube"))}><FaCopy /></button>
                                                        </>}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </td>
                <td>{formatDate(new Date(row.date))}</td>
            </tr>
        ));
    };

    // function isUrlValid(string) {
    //     const regex = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
    //     const matchesRegex = regex.test(string);

    //     if (!matchesRegex) {
    //       return false;
    //     }

    //     try {
    //       let url = string;
    //       if (!/^http(s)?:\/\//i.test(string)) {
    //         url = 'http://' + string;

    //       }
    //       new URL(url);
    //       return true;
    //     } catch (err) {
    //       return false;
    //     }
    //   }

    function formatUrl(string, type) {
        if (string !== "N/A") {
            if (/^(http(s)?:\/\/)/i.test(string)) {
                return string;
            } else if (/^(www\.)/i.test(string)) {
                return `https://${string}`;
            } else {
                switch (type) {
                    case 'Telegram':
                        return `https://t.me/${string}`;
                    case 'Discord':
                        return `https://www.discord.com/${string}`;
                    case 'Xtwitter':
                        return `https://x.com/${string}`;
                    case 'Youtube':
                        return `https://www.youtube.com/${string}`;
                    default:
                        return string; // Or handle unknown types as needed
                }
            }
        } else {
            return string;
        }
    }

    function copyToClipboard(text) {
        navigator.clipboard.writeText(text).then(() => {
            toast.success("Link copied to clipboard", { autoClose: 2000 });
        }).catch(err => {
            console.error('Failed to copy: ', err);
        });
    }

    return (
        <div className='list-user'>
            <div className='container'>
                <div className='row'>
                    <div className='col'>
                        <h1 className='py-5' style={{ color: "rgb(8, 29, 68)" }}>Whitelist User</h1>

                        {/* <ul class="nav nav-pills mb-3 justify-content-center gap-5" id="pills-tab" role="tablist">
                            <li class="nav-item" role="presentation">
                                <button class="nav-link active" id="pills-internal-tab" data-bs-toggle="pill" data-bs-target="#pills-internal" type="button" role="tab" aria-controls="pills-internal" aria-selected="true">InternalLine</button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="nav-link" id="pills-external-tab" data-bs-toggle="pill" data-bs-target="#pills-external" type="button" role="tab" aria-controls="pills-external" aria-selected="false">ExternalLine</button>
                            </li>

                        </ul> */}



                        <ul className="nav nav-pills mb-3 justify-content-center align-items-center gap-3" id="pills-tab" role="tablist">
                            <li className="nav-item" role="presentation">
                                {/* <div className=''>
                                    <form action="/search" method="GET">
                                        <input type="text" id="query" name="query" placeholder='Type...' required />
                                        <button type="submit" className='search py-1 px-2 m-2'>Search</button>
                                    </form>
                                </div> */}
                            </li>
                            <li className="nav-item" role="presentation">
                                <button
                                    className={`nav-link ${activeTab === 'internal' ? 'active' : ''}`}
                                    id="pills-internal-tab"
                                    type="button"
                                    role="tab"
                                    aria-controls="pills-internal"
                                    aria-selected={activeTab === 'internal'}
                                    onClick={() => setActiveTab('internal')}
                                >
                                    Internal Line
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button
                                    className={`nav-link ${activeTab === 'external' ? 'active' : ''}`}
                                    id="pills-external-tab"
                                    type="button"
                                    role="tab"
                                    aria-controls="pills-external"
                                    aria-selected={activeTab === 'external'}
                                    onClick={() => setActiveTab('external')}
                                >
                                    External Line
                                </button>
                            </li>
                        </ul>

                        <div class="tab-content" id="pills-tabContent">
                            <div
                                className={`tab-pane fade ${activeTab === 'internal' ? 'show active' : ''}`}
                                id="pills-internal"
                                role="tabpanel"
                                aria-labelledby="pills-internal-tab"
                                tabIndex="0"
                            >
                                <table class="user-table">
                                    <thead>
                                        <tr>
                                            <th>S.No</th>
                                            <th>User name</th>
                                            <th>User address</th>
                                            <th>Id</th>
                                            <th>Line</th>
                                            <th>userDetails</th>
                                            <th>Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {renderInternalTableRows(InternalArr)}
                                    </tbody>
                                </table>
                                <ReactPaginate
                                    previousLabel={"Previous"}
                                    nextLabel={"Next"}
                                    pageCount={totalPages}
                                    onPageChange={changePage}
                                    containerClassName={"navigationButtons"}
                                    previousLinkClassName={"previousButton"}
                                    nextLinkClassName={"nextButton"}
                                    disabledClassName={"navigationDisabled"}
                                    activeClassName={"navigationActive"}
                                />
                            </div>
                            <div
                                className={`tab-pane fade ${activeTab === 'external' ? 'show active' : ''}`}
                                id="pills-external"
                                role="tabpanel"
                                aria-labelledby="pills-external-tab"
                                tabIndex="0"
                            >
                                <table class="user-table">
                                    <thead>
                                        <tr>
                                            <th>S.No</th>
                                            <th>User name</th>
                                            <th>User address</th>
                                            <th>Id</th>
                                            <th>Line</th>
                                            <th>userDetails</th>
                                            <th>Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {renderExternalTableRows(externalArr)}
                                    </tbody>
                                </table>

                                <ReactPaginate
                                    previousLabel={"Previous"}
                                    nextLabel={"Next"}
                                    pageCount={totalPages1}
                                    onPageChange={changePage1}
                                    containerClassName={"navigationButtons"}
                                    previousLinkClassName={"previousButton"}
                                    nextLinkClassName={"nextButton"}
                                    disabledClassName={"navigationDisabled"}
                                    activeClassName={"navigationActive"}
                                />
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    )
}

export default Whitelistuser