import React, { useState, useEffect } from 'react';
import "../pages/Dashboard.css";
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Form from 'react-bootstrap/Form';
import configration from '../config/config';
import { ToastContainer, toast } from "react-toastify";
import { logDOM } from '@testing-library/react';
import axios from 'axios';


function Sitesetting() {
    const initialValues = {
        Telegram: '',
        Twitter: '',
        Instagram: '',
        Youtube: '',
    };
    const validationSchema = Yup.object({
        Telegram: Yup.string().required('Telegram is required'),
        Twitter: Yup.string().required('Twitter is required'),
        Instagram: Yup.string().required('Instagram is required'),
        Youtube: Yup.string().required('Youtube is required'),
    });

    const [editFormData, setEditFormData] = useState({
        _id: '',
        Telegram: '',
        Whatsapp: '',
        Twitter: '',
        Instagram: '',
        Youtube: '',
    });
    const [errors, setErrors] = useState({});
    const [Loadinglinks, setIsLoadinglinks] = useState(false);
    const [file, setFile] = useState(null);
    const [oldImageUrl, setOldImageUrl] = useState('');
    const [OldImageId, setOldImageId] = useState('')
    const [title, setTitle] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    const getDatalink = async () => {
        try {
            const response1 = await axios.get(`${configration.localhostBackend}getlinks`)
            // console.log("getlinks------", response1.data.datalinks[0]);
            // setLinksdatas(response1.data)
            setEditFormData(response1.data.datalinks[0] || {});
        } catch (error) {
            console.error(error);
        }
    }

    const getOldImage = async () => {
        try {
            const response = await axios.get(`${configration.localhostBackend}getimgdata`); // Update with your API endpoint

            const getdata = response.data.data

            if (getdata && getdata.length > 0) {
                setOldImageUrl(response.data.data[0].image); // Assuming you want to display the first image
                setOldImageId(response.data.data[0]._id)
            }
        } catch (error) {
            console.error('Error fetching old image:', error);
        }
    }

    useEffect(() => {
        getDatalink()
        getOldImage()
    }, [])

    const handleupdateSubmit = async (e) => {
        e.preventDefault();

        const validationErrors = {};
        const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;

        if (!editFormData.Telegram) {
            validationErrors.Telegram = 'Telegram Cannot be empty';
        } else if (!urlPattern.test(editFormData.Telegram)) {
            validationErrors.Telegram = 'Invalid Telegram URL';
        }
        // else if (editFormData.Telegram.split('.com').length - 1 > 1 ||
        //     !editFormData.Telegram.endsWith('.com')) {
        //     validationErrors.Telegram = '.com is required';
        // }

        if (!editFormData.Whatsapp) {
            validationErrors.Whatsapp = 'Telegramchat Cannot be empty';
        } else if (!urlPattern.test(editFormData.Whatsapp)) {
            validationErrors.Whatsapp = 'Invalid Telegramchat URL';
        }
        // else if (editFormData.Telegramchat.split('.com').length - 1 > 1 ||
        //     !editFormData.Telegramchat.endsWith('.com')) {
        //     validationErrors.Telegramchat = '.com is required';
        // }

        if (!editFormData.Twitter) {
            validationErrors.Twitter = 'Twitter Cannot be empty';
        } else if (!urlPattern.test(editFormData.Twitter)) {
            validationErrors.Twitter = 'Invalid Twitter URL';
        }
        // else if (editFormData.Twitter.split('.com').length - 1 > 1 ||
        //     !editFormData.Twitter.endsWith('.com')) {
        //     validationErrors.Twitter = '.com is required';
        // }

        if (!editFormData.Instagram) {
            validationErrors.Instagram = 'Instagram Cannot be empty';
        } else if (!urlPattern.test(editFormData.Instagram)) {
            validationErrors.Instagram = 'Invalid Instagram URL';
        }
        // else if (editFormData.Instagram.split('.com').length - 1 > 1 ||
        //     !editFormData.Instagram.endsWith('.com')) {
        //     validationErrors.Instagram = '.com is required';
        // }

        if (!editFormData.Youtube) {
            validationErrors.Youtube = 'Youtube Cannot be empty';
        } else if (!urlPattern.test(editFormData.Youtube)) {
            validationErrors.Youtube = 'Invalid Youtube URL';
        }
        // else if (editFormData.Youtube.split('.com').length - 1 > 1 ||
        //     !editFormData.Youtube.endsWith('.com')) {
        //     validationErrors.Youtube = '.com is required';
        // }


        setErrors(validationErrors);
        if (Object.keys(validationErrors).length === 0) {
            setIsLoadinglinks(true)
            try {
                const response = await axios.post(`${configration.localhostBackend}updatelinks`, editFormData);

                toast.success("Link updated");
                setIsLoadinglinks(false)
                getDatalink()
            } catch (error) {
                console.error("Error updating link", error);
                toast.error("Link update failed...");
                setIsLoading(false)
            }
        }

    };

    const updatedata = (e) => {
        let data = { ...editFormData, [e.target.name]: e.target.value };

        setEditFormData(data);
        setErrors({ ...errors, [e.target.name]: '' });
    }

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);

    };

    const handleChange = (e) => {
        const titleval = e.target.value;

        setTitle(titleval)
    };

    const handleUpdate = async (e) => {
        e.preventDefault();

        if (!file) {
            toast.warn('Please select a file to update the image.');
            return;
        }

        // if (!title) {
        //     toast.warn('Title is required.');
        //     return;
        // }

        const allowedFileTypes = ['image/jpeg', 'image/png', 'image/jpg', 'image/jfif', 'application/pdf'];
        if (!allowedFileTypes.includes(file.type)) {
            toast.warn('Invalid file type. Only JPG, JPEG, PNG, and jfif files are allowed.', {
                position: toast.POSITION.TOP_CENTER,
                className: 'custom-logo-toast',
            });
            // alert('Invalid file type. Only JPG, JPEG, PNG, and jfif files are allowed.')
            setTimeout(() => {
                window.location.reload()
            }, 3000);
            return;
        }

        setIsLoading(true);

        const formData = new FormData();
        formData.append('image', file);
        formData.append('id', OldImageId)
        // formData.append('title', title)

        try {

            const response = await axios.post(`${configration.localhostBackend}updateimg`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });


            if (response.status === 200) {
                // Image updated successfully

                // alert('Image updated successfully!');
                toast.success('logo uploaded successfully', {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 3000,
                    hideProgressBar: true,
                });
                setIsLoading(false);
                setFile(null);
                setTimeout(() => {
                    window.location.reload()
                }, 2000);
                // You may also update the oldImage state with the new image URL here
            } else {
                // Handle error response from the server
                console.error('Error:', response);
            }


        } catch (error) {
            console.error('Error updating image:', error);
            toast.error('Error updating image.');
            setIsLoading(false);
        }

    };

    return (
        <div className='site-setting'>
            <h1 className='pt-5' style={{ color: "rgb(8, 29, 68)" }}>Site Setting</h1>
            <h3 className='' style={{ color: "rgb(8, 29, 68)" }}>social media links uploads</h3>
            <div>

                {/* <Tabs
                    defaultActiveKey="logo"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                >
                    <Tab eventKey="logo" title="logo image">
                        <div>

                            <div class="container">
                                OLDimage:{oldImageUrl && <img src={`${configration.localhostBackend}uploads/${oldImageUrl}`} alt="Old Image" width={8} />}
                                <form onSubmit={handleUpdate}>
                                    <input type='text' name='title' />
                                    <label>Select a new image:</label>
                                    <input type="file" name="logoimage" accept="image/gif" onChange={handleFileChange} />
                                    <button type="submit">
                                        Update
                                    </button>
                                    <br /><br />
                                </form>

                                <form onSubmit={handleUpdate}>
                                    <div class="mb-3">
                                        <label for="exampleInputEmail1" class="form-label">Title:</label>
                                        <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" onChange={handleChange} />
                                    </div>
                                    <div class="mb-3">
                                        <label for="exampleInputPassword1" class="form-label">Select a new image:</label>
                                        <input type="file" class="form-control" id="exampleInputPassword1" accept="image/gif" onChange={handleFileChange} />
                                    </div>
                                    {isLoading ? <button type="submit" class="btn btn-primary">
                                        <div class="spinner-border" role="status">
                                            <span class="sr-only" ></span>
                                        </div>
                                    </button> :
                                        <button type="submit" class="btn btn-primary">Submit</button>}
                                </form>
                            </div>
                        </div>

                    </Tab>
                    <Tab eventKey="profile" title="social media links">
                        <div class="container">
                            <h3>social media links uploads</h3>

                            <form onSubmit={handleupdateSubmit}>
                                <div className='text-start my-2'>
                                    <input className="form-control" type="hidden" name="id" id="id" value={editFormData._id || ''} onChange={updatedata} />
                                </div>
                                <div className='text-start my-2'>
                                    <label htmlFor="Telegram" className="form-label">Telegram</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        id="Telegram"
                                        name='Telegram'
                                        value={editFormData.Telegram || ''}
                                        onChange={updatedata}
                                    />
                                </div>
                                {errors.Telegram && <span style={{ color: 'red' }}>{errors.Telegram}</span>}
                                <div className='text-start my-2'>
                                    <label htmlFor="Whatsapp" className="form-label">Telegram chat</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        id="Whatsapp"
                                        name='Whatsapp'
                                        value={editFormData.Whatsapp || ''}
                                        onChange={updatedata}
                                    />
                                </div>
                                {errors.Whatsapp && <span style={{ color: 'red' }}>{errors.Whatsapp}</span>}
                                <div className='text-start my-2'>
                                    <label htmlFor="Twitter" className="form-label">Twitter</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        id="Twitter"
                                        name='Twitter'
                                        value={editFormData.Twitter || ''}
                                        onChange={updatedata}
                                    />
                                </div>
                                {errors.Twitter && <span style={{ color: 'red' }}>{errors.Twitter}</span>}
                                <div className='text-start my-2'>
                                    <label htmlFor="Instagram" className="form-label">Instagram</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        id="Instagram"
                                        name='Instagram'
                                        value={editFormData.Instagram || ''}
                                        onChange={updatedata}
                                    />
                                </div>
                                {errors.Instagram && <span style={{ color: 'red' }}>{errors.Instagram}</span>}
                                <div className='text-start my-2'>
                                    <label htmlFor="Youtube" className="form-label">Youtube</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        id="Youtube"
                                        name='Youtube'
                                        value={editFormData.Youtube || ''}
                                        onChange={updatedata}
                                    />
                                </div>
                                {errors.Youtube && <span style={{ color: 'red' }}>{errors.Youtube}</span>}
                                <br />
                                {Loadinglinks ? (
                                    <button type='submit' className="btn btn-primary">
                                        <div class="spinner-border" role="status">
                                            <span class="sr-only" ></span>
                                        </div>
                                    </button>) : (<button type='submit' className="btn btn-primary">
                                        update
                                    </button>)}

                            </form>

                        </div>
                    </Tab>

                </Tabs> */}

                <div class="container">
                    <div className='row justify-content-center my-5'>

                        <form onSubmit={handleupdateSubmit} style={{ width: "800px" }}>
                            <div className='text-start my-2'>
                                <input className="form-control" type="hidden" name="id" id="id" value={editFormData._id || ''} onChange={updatedata} />
                            </div>
                            <div className='text-start my-2'>
                                <label htmlFor="Telegram" className="form-label">Telegram</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    id="Telegram"
                                    name='Telegram'
                                    value={editFormData.Telegram || ''}
                                    onChange={updatedata}
                                />
                            </div>
                            {errors.Telegram && <span style={{ color: 'red' }}>{errors.Telegram}</span>}
                            <div className='text-start my-2'>
                                <label htmlFor="Whatsapp" className="form-label">Whatsapp</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    id="Whatsapp"
                                    name='Whatsapp'
                                    value={editFormData.Whatsapp || ''}
                                    onChange={updatedata}
                                />
                            </div>
                            {errors.Whatsapp && <span style={{ color: 'red' }}>{errors.Whatsapp}</span>}
                            <div className='text-start my-2'>
                                <label htmlFor="Twitter" className="form-label">Twitter</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    id="Twitter"
                                    name='Twitter'
                                    value={editFormData.Twitter || ''}
                                    onChange={updatedata}
                                />
                            </div>
                            {errors.Twitter && <span style={{ color: 'red' }}>{errors.Twitter}</span>}
                            <div className='text-start my-2'>
                                <label htmlFor="Instagram" className="form-label">Instagram</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    id="Instagram"
                                    name='Instagram'
                                    value={editFormData.Instagram || ''}
                                    onChange={updatedata}
                                />
                            </div>
                            {errors.Instagram && <span style={{ color: 'red' }}>{errors.Instagram}</span>}
                            <div className='text-start my-2'>
                                <label htmlFor="Youtube" className="form-label">Youtube</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    id="Youtube"
                                    name='Youtube'
                                    value={editFormData.Youtube || ''}
                                    onChange={updatedata}
                                />
                            </div>
                            {errors.Youtube && <span style={{ color: 'red' }}>{errors.Youtube}</span>}
                            <br />
                            {Loadinglinks ? (
                                <button type='submit' className="btn btn-primary">
                                    <div class="spinner-border" role="status">
                                        <span class="sr-only" ></span>
                                    </div>
                                </button>) : (<button type='submit' className="btn" style={{ background: "rgb(8 29 68)", color: "white", padding: "5px 30px" }}>
                                    update
                                </button>)}

                        </form>
                    </div>

                </div>


            </div >

        </div>
    )
}

export default Sitesetting