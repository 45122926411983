var env = "live"
var configration = {};
if (env === "local") {
    configration = {
        DB: "mongodb+srv://D-MLM:qSMkxClv7zhqxwxy@cluster0.e66dyaf.mongodb.net/",
        localhostBackend: 'http://localhost:5001/',
        localhostFrontend: 'http://localhost:3000/',

        DCXRPC_URL: "https://mainnetcoin.d-ecosystem.io/",
        DCXCHAIN_ID: 5272,
        DCXCHAIN_HEX_ID: '0x1498',
        DCX_SYMBOL: "DCX",
        DCX_BLOCKEXPLORER: "https://mainnet-explorer.d-ecosystem.io",
        DCX_NETWORK_NAME: 'D-Chain',
        DCX_DECIMAL: 18,

        YOUR_NODE_RPC_URL: 'https://mainnetcoin.d-ecosystem.io/',
        REGISTRATION_ZERO_ADDRESS: '0x0000000000000000000000000000000000000000',
        D_CHAIN_EXPLORER_TXHASH_URL: 'https://mainnet-explorer.d-ecosystem.io/tx/',
        D_CHAIN_EXPLORER_ADDRESS_URL: 'https://mainnet-explorer.d-ecosystem.io/address/',
        D_CHAIN_OVER_ALL_HISTORY_API_URL: 'https://mainnet-explorer.d-ecosystem.io/api?module=account&action=txlist&address=',
        D_CHAIN_OVER_ALL_HISTORY_API_KEY: '&apikey=',

        REGISTER_HISTORY_SEE_MORE_LESS: 10,

        TRANSACTION_INDEX_PAGE_NUM: 10,

        // DUSD_TOKEN_ADDRESS: '0x9dA31A57Dc41C548147F3bF07e0cb1d6a4b7C705',
        // PLAN_1_CONTRACT_ADDRESS: '0x5bAa0171EF2342D5965eDc5956ab126a4C369174',
        // DEMO_CORE_PLAN_CONTRACT_ADDRESS: '0x0Bb1335847D5413845159e367954460325388D0c',
        // D_REWARD_STORED_ADDRESS: "0xfb2F04C8636812977ac73AAA05832BE1Df8Da261",
        // ADMIN_WALLET_ADDRESS: '0xfb2F04C8636812977ac73AAA05832BE1Df8Da261',

        // -----------------------------Testing Contract Address--------------------------
        DUSD_TOKEN_ADDRESS: "0x31bb32680709907b4a2cd0f0baf718d73c4d6b62",
        PLAN_1_CONTRACT_ADDRESS: "0x6a86ae1132CC249286C7822E62FD9Bd89882252A",
        DEMO_CORE_PLAN_CONTRACT_ADDRESS: "0x75F2D5f4f12d267a62f67c6dF223fF572258E599",
        D_REWARD_STORED_ADDRESS: "0xBd59250aF16a5DBB0e5b7B2C2aC93aa5A2b0a7eF",
        ADMIN_WALLET_ADDRESS: "0x4c9213B3e7CCCAE00a021eaB36C1585c6CC28FD4",
        // -----------------------------Testing Contract Address--------------------------
    }
}   
else if (env === "demo") {
    configration = {
        DB: "mongodb+srv://D-MLM:qSMkxClv7zhqxwxy@cluster0.e66dyaf.mongodb.net/",
        localhostBackend: "https://staging-mlm-api.d-ecosystem.io/",
        localhostFrontend: "https://staging-mlm-adminpanel.d-ecosystem.io/",
        DCXRPC_URL: "https://mainnetcoin.d-ecosystem.io/",
        DCXCHAIN_ID: 5272,
        DCXCHAIN_HEX_ID: '0x1498',
        DCX_SYMBOL: "DCX",
        DCX_BLOCKEXPLORER: "https://mainnet-explorer.d-ecosystem.io",
        DCX_NETWORK_NAME: 'D-Chain',
        DCX_DECIMAL: 18,
        YOUR_NODE_RPC_URL: 'https://mainnetcoin.d-ecosystem.io/',
        REGISTRATION_ZERO_ADDRESS: '0x0000000000000000000000000000000000000000',
        D_CHAIN_EXPLORER_TXHASH_URL: 'https://mainnet-explorer.d-ecosystem.io/tx/',
        D_CHAIN_EXPLORER_ADDRESS_URL: 'https://mainnet-explorer.d-ecosystem.io/address/',
        D_CHAIN_OVER_ALL_HISTORY_API_URL: 'https://mainnet-explorer.d-ecosystem.io/api?module=account&action=txlist&address=',
        D_CHAIN_OVER_ALL_HISTORY_API_KEY: '&apikey=',
        REGISTER_HISTORY_SEE_MORE_LESS: 10,
        TRANSACTION_INDEX_PAGE_NUM: 10,

        // DUSD_TOKEN_ADDRESS: '0x9dA31A57Dc41C548147F3bF07e0cb1d6a4b7C705',
        // PLAN_1_CONTRACT_ADDRESS: '0x5bAa0171EF2342D5965eDc5956ab126a4C369174',
        // DEMO_CORE_PLAN_CONTRACT_ADDRESS: '0x0Bb1335847D5413845159e367954460325388D0c',
        // D_REWARD_STORED_ADDRESS: "0x7f216358dd6Dde598B0d546AF0f4E2b65C5f24bC",
        // ADMIN_WALLET_ADDRESS: '0xAf1c1fB8C1a1B365177E559483295a7d461FC976',

        // -----------------------------Testing Contract Address--------------------------
        DUSD_TOKEN_ADDRESS: "0x8F90ce57931Dec67b758ABf934DE5215BDE4cB9E",
        PLAN_1_CONTRACT_ADDRESS: "0x5083211e10fa1b7b71Bd63cB2809067417e683cD",
        DEMO_CORE_PLAN_CONTRACT_ADDRESS: "0x0cdbcC047cD8Fb4e3B4dcF76D4FbfE7CFCb5f916",
        D_REWARD_STORED_ADDRESS: "0x7f216358dd6Dde598B0d546AF0f4E2b65C5f24bC",
        ADMIN_WALLET_ADDRESS: "0x20cc031f6F1DC86AA4532A32957d32EA25714442",
        // -----------------------------Testing Contract Address--------------------------
    }
}else{
    // -------------------   LIVE CONFIG  ---------------------------------
    configration = {
        DB: "mongodb+srv://D-MLM:qSMkxClv7zhqxwxy@cluster0.e66dyaf.mongodb.net/",
        localhostBackend: "https://api.propelx.io/",
        localhostFrontend: "https://adminpanel.propelx.io/",
        DCXRPC_URL: "https://mainnetcoin.d-ecosystem.io/",
        DCXCHAIN_ID: 5272,
        DCXCHAIN_HEX_ID: '0x1498',
        DCX_SYMBOL: "DCX",
        DCX_BLOCKEXPLORER: "https://mainnet-explorer.d-ecosystem.io",
        DCX_NETWORK_NAME: 'D-Chain',
        DCX_DECIMAL: 18,
        YOUR_NODE_RPC_URL: 'https://mainnetcoin.d-ecosystem.io/',
        REGISTRATION_ZERO_ADDRESS: '0x0000000000000000000000000000000000000000',
        D_CHAIN_EXPLORER_TXHASH_URL: 'https://mainnet-explorer.d-ecosystem.io/tx/',
        D_CHAIN_EXPLORER_ADDRESS_URL: 'https://mainnet-explorer.d-ecosystem.io/address/',
        D_CHAIN_OVER_ALL_HISTORY_API_URL: 'https://mainnet-explorer.d-ecosystem.io/api?module=account&action=txlist&address=',
        D_CHAIN_OVER_ALL_HISTORY_API_KEY: '&apikey=',
        REGISTER_HISTORY_SEE_MORE_LESS: 10,
        TRANSACTION_INDEX_PAGE_NUM: 10,

        // DUSD_TOKEN_ADDRESS: '0x9dA31A57Dc41C548147F3bF07e0cb1d6a4b7C705',
        // PLAN_1_CONTRACT_ADDRESS: '0x5bAa0171EF2342D5965eDc5956ab126a4C369174',
        // DEMO_CORE_PLAN_CONTRACT_ADDRESS: '0x0Bb1335847D5413845159e367954460325388D0c',
        // D_REWARD_STORED_ADDRESS: "0x7f216358dd6Dde598B0d546AF0f4E2b65C5f24bC",
        // ADMIN_WALLET_ADDRESS: '0xAf1c1fB8C1a1B365177E559483295a7d461FC976',

        // -----------------------------Testing Contract Address--------------------------
        DUSD_TOKEN_ADDRESS: "0x31bb32680709907b4a2cd0f0baf718d73c4d6b62",
        PLAN_1_CONTRACT_ADDRESS: "0x793ce2977825f3e14608836cD7ef3d7d8138830f",
        DEMO_CORE_PLAN_CONTRACT_ADDRESS: "0x75F2D5f4f12d267a62f67c6dF223fF572258E599",
        D_REWARD_STORED_ADDRESS: "0x2d6461E591415C263fb8B89D974181ED24Fbf04a",
        ADMIN_WALLET_ADDRESS: "0x4a3555Eb8fb9D4FCcFaA9c3aa546C14EEd540538",
        // -----------------------------Testing Contract Address--------------------------
    } 
}

export default configration;