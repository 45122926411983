import React, { useState, useEffect, useMemo } from 'react';
import '../pages/Dashboard.css';
import Dashboardtwo from '../pages/Dashboard-2';
import axios from 'axios';
import config from '../config/config';
import Web3 from 'web3';
import { useMetaMask } from "metamask-react";
import DataTable from "react-data-table-component";
import { BsFillFileBarGraphFill, BsArrowRight } from 'react-icons/bs';
import { AiOutlineArrowDown } from 'react-icons/ai';


import { FiLogOut } from "react-icons/fi";
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Button, Modal, Form, ListGroup, ListGroupItem } from 'react-bootstrap';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import DUSDtokenAbi from './ABI/DUSDtokenAbi.json';
import Plan1Abi from './ABI/Plan1Abi.json';
import DemoCorePlanAbi from './ABI/DemoCorePlan.json';
import ToastApi from '../config/toastApi';
import Window_Ethereum_Config_Api from '../config/windowEthereumConfig'

import { BiLock, BiLockOpen } from 'react-icons/bi';
import { MdEdit } from 'react-icons/md';
import { RiDeleteBin6Fill } from 'react-icons/ri';
import { IoReloadCircle } from 'react-icons/io5';
import CreateFaq from './Editor';
import dlogo from "../Asset/images/Asset 2.png";
import Withdraw from './withdraw';
import $ from 'jquery'
import Sitesetting from './sitesetting';
import Transactionsection from './Transactionsection';
import Whitelistrequest from './whitelistrequest';
import Whitelistdata from './whitelistdata';
import Whitelistuser from './whitelistuser';

import { MdDashboard } from "react-icons/md";
import { BiMoneyWithdraw } from "react-icons/bi";
import { BiTransfer } from "react-icons/bi";
import { IoSettings } from "react-icons/io5";
import { FaQuestionCircle } from "react-icons/fa";
import { GoSignIn } from "react-icons/go";
import { LuListEnd } from "react-icons/lu";
import { MdPersonAddAlt1 } from "react-icons/md";


import { VscGitPullRequestGoToChanges } from "react-icons/vsc";
import { FaDatabase } from "react-icons/fa";
import { FaUserCircle } from "react-icons/fa";
import Careerform from './Careerform';





$(document).ready(function () {

    // $('.tree li ul li a').click(function (e) {
    //   e.preventDefault();
    //   $(this).closest("li").find("[class^='ul_submenu']").slideToggle();
    // });
    $(function () {
        $('.first-level-tree-structure ul').hide();
        $('.vertical-tree>ul').show();
        $('.vertical-tree ul.active').show();
        $('.vertical-tree li').on('click', function (e) {
            var children = $(this).find('> ul');
            if (children.is(":visible")) children.hide('fast').removeClass('active');
            else children.show('fast').addClass('active');
            e.stopPropagation();
        });
    });

});



$(document).ready(function () {
    if ($(window).width() < 600) {       // if width is less than 600px
        $(".nav-link").click(function () {
            $("#mySidenav").css("width", "0px");
        });
    }
    else {
        // $('#v-pills-profile-tab').click(function () {
        //   location.reload();
        // });

    }

    $("#mobile-three-line-collapse").click(function () {
        $("#mySidenav").css("width", "250px");
    });
    $("#mobile-close-collapse").click(function () {
        $("#mySidenav").css("width", "0px");
    });
    $(".dashboard-profile-table-tabs-section-hide").hide();
    $(".details-button-navigation button").click(function () {
        $(".dashboard-profile-section-hide").hide();
        $(".dashboard-profile-table-tabs-section-hide").show();
        $("#mySidenav").css("width", "100px");
        $(".dashboard-main-section").css("margin-left", "100px");
        $(".dashboard-navbar-brand img").css("width", "50px");
        $(".dashboard-navbar-brand img").css("height", "50px");
        $("#mobile-close-arrow-left").hide();
        $(".dashboard-text-1").hide();
        $("#mobile-close-arrow-right").show();
    });
    $(".button-dashboard-table-back").click(function () {
        $(".dashboard-profile-section-hide").show();
        $(".dashboard-profile-table-tabs-section-hide").hide();
        $("#mySidenav").css("width", "250px");
        $(".dashboard-main-section").css("margin-left", "250px");
        $(".dashboard-navbar-brand img").css("width", "80px");
        $(".dashboard-navbar-brand img").css("height", "80px");
        $("#mobile-close-arrow-left").show();
        $(".dashboard-text-1").show();
        $("#mobile-close-arrow-right").hide();
    });



    $("#mobile-close-arrow-right").hide();
    $("#mobile-close-arrow-left").click(function () {
        $("#mySidenav").css("width", "100px");
        $(".dashboard-main-section").css("margin-left", "100px");
        $(".dashboard-navbar-brand img").css("width", "50px");
        $(".dashboard-navbar-brand img").css("height", "50px");
        $("#mobile-close-arrow-left").hide();
        $(".dashboard-text-1").hide();
        $("#mobile-close-arrow-right").show();
    });
    $("#mobile-close-arrow-right").click(function () {
        $("#mySidenav").css("width", "250px");
        $(".dashboard-main-section").css("margin-left", "250px");
        $(".dashboard-navbar-brand img").css("width", "80px");
        $(".dashboard-navbar-brand img").css("height", "80px");
        $("#mobile-close-arrow-left").show();
        $(".dashboard-text-1").show();
        $("#mobile-close-arrow-right").hide();
    });


    if ($('#mySidenav').css('width') === '100px') {
        $('#mySidenav').css('a');
    }
});

function readURL(input) {
    if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = function (e) {
            $('#imagePreview').css('background-image', 'url(' + e.target.result + ')');
            $('#imagePreview').hide();
            $('#imagePreview').fadeIn(650);
        }
        reader.readAsDataURL(input.files[0]);
    }
}
$("#imageUpload").change(function () {
    readURL(this);
});


function Dashboard() {


    const navigate = useNavigate()
    const logOut = () => {
        localStorage.clear();
        navigate('/')
    }

    return (
        <div>
            {/* <ToastContainer/> */}
            <span id="mobile-three-line-collapse">&#9776;</span>

            <div class="dashboard-sidenav-section" id="mySidenav">


                <div class="d-flex flex-row">
                    <div class="ms-auto me-3">
                        <span id="mobile-close-collapse"><i class="bi bi-x-lg"></i></span>
                    </div>
                </div>
                {/* <div class="d-flex flex-row">
                    <div class="ms-auto">
                        <span id="mobile-close-arrow-left"><i class="bi bi-chevron-left"></i></span>
                        <span id="mobile-close-arrow-right"><i class="bi bi-chevron-right"></i></span>
                    </div>
                </div> */}
                <div class="d-flex flex-row">
                    <div class="mx-auto">
                        <a class="dashboard-navbar-brand" href="/dashboard"><img src={dlogo} alt="logo" /></a>
                    </div>
                </div>

                <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">

                    <button class="nav-link active" id="v-pills-Dashboard-tab" data-bs-toggle="pill" data-bs-target="#v-pills-Dashboard"
                        type="button" role="tab" aria-controls="v-pills-Dashboard" aria-selected="false" >
                        <span class="dashboard-text-1"><MdDashboard className='dashboard-icon' />Dashboard</span></button>

                    <button class="nav-link" id="v-pills-Withdraw-tab" data-bs-toggle="pill"
                        data-bs-target="#v-pills-Withdraw" type="button" role="tab" aria-controls="v-pills-Withdraw"
                        aria-selected="false" ><span class="dashboard-text-1"><BiMoneyWithdraw className='dashboard-icon' />
                            Send Rewards</span></button>

                    <button class="nav-link" id="v-pills-Transaction-tab" data-bs-toggle="pill" data-bs-target="#v-pills-Transaction"
                        type="button" role="tab" aria-controls="v-pills-Transaction" aria-selected="false"><span class="dashboard-text-1"><BiTransfer className='dashboard-icon' />Transaction</span></button>

                    <button class="nav-link" id="v-pills-site-settings-tab" data-bs-toggle="pill"
                        data-bs-target="#v-pills-site-settings" type="button" role="tab" aria-controls="v-pills-site-settings"
                        aria-selected="false"><span class="dashboard-text-1" ><IoSettings className='dashboard-icon' />Site Settings</span></button>

                    <button class="nav-link" id="v-pills-whitelistrequest-tab" data-bs-toggle="pill"
                        data-bs-target="#v-pills-whitelistrequest" type="button" role="tab" aria-controls="v-pills-whitelistrequest"
                        aria-selected="false"><span class="dashboard-text-1" ><VscGitPullRequestGoToChanges className='dashboard-icon' />Whitelist Request</span></button>

                    <button class="nav-link" id="v-pills-whitelistdata-tab" data-bs-toggle="pill"
                        data-bs-target="#v-pills-whitelistdata" type="button" role="tab" aria-controls="v-pills-whitelistdata"
                        aria-selected="false"><span class="dashboard-text-1" ><FaDatabase className='dashboard-icon' />Whitelist Data</span></button>

                    <button class="nav-link" id="v-pills-whitelistuser-tab" data-bs-toggle="pill"
                        data-bs-target="#v-pills-whitelistuser" type="button" role="tab" aria-controls="v-pills-whitelistuser"
                        aria-selected="false"><span class="dashboard-text-1" ><FaUserCircle className='dashboard-icon' />Whitelist User</span></button>

                    <button class="nav-link" id="v-pills-careerform-tab" data-bs-toggle="pill"
                        data-bs-target="#v-pills-careerform" type="button" role="tab" aria-controls="v-pills-careerform"
                        aria-selected="false"><span class="dashboard-text-1"><MdPersonAddAlt1 className='dashboard-icon' />Career Request</span></button>

                    <button class="nav-link" id="v-pills-FAQ-tab" data-bs-toggle="pill"
                        data-bs-target="#v-pills-FAQ" type="button" role="tab" aria-controls="v-pills-FAQ"
                        aria-selected="false"><span class="dashboard-text-1" ><FaQuestionCircle className='dashboard-icon' />FAQ</span></button>

                    <button class="nav-link" id="v-pills-signout-tab" data-bs-toggle="pill" data-bs-target="#v-pills-signout"
                        type="button" role="tab" aria-controls="v-pills-signout" aria-selected="false" onClick={logOut}><span class="dashboard-text-1" ><GoSignIn className='dashboard-icon' />Signout</span></button>



                </div>

            </div>

            <div class="dashboard-main-section">
                <div class="tab-content" id="v-pills-tabContent">

                    <div class="tab-pane fade  show active" id="v-pills-Dashboard" role="tabpanel" aria-labelledby="v-pills-Dashboard-tab">
                        <Dashboardtwo />
                    </div>

                    <div class="tab-pane fade  " id="v-pills-Withdraw" role="tabpanel"
                        aria-labelledby="v-pills-Withdraw-tab" >
                        <Withdraw />
                    </div>


                    <div class="tab-pane fade mt-5 pt-5" id="v-pills-Transaction" role="tabpanel" aria-labelledby="v-pills-Transaction-tab">

                        <Transactionsection />

                    </div>

                    <div class="tab-pane fade " id="v-pills-site-settings" role="tabpanel"
                        aria-labelledby="v-pills-site-settings-tab">
                        <Sitesetting />
                    </div>

                    <div class="tab-pane fade " id="v-pills-whitelistrequest" role="tabpanel"
                        aria-labelledby="v-pills-whitelistrequest-tab">
                        <Whitelistrequest />
                    </div>

                    <div class="tab-pane fade " id="v-pills-whitelistdata" role="tabpanel"
                        aria-labelledby="v-pills-whitelistdata-tab">
                        <Whitelistdata />
                    </div>

                    <div class="tab-pane fade " id="v-pills-whitelistuser" role="tabpanel"
                        aria-labelledby="v-pills-whitelistuser-tab">
                        <Whitelistuser />
                    </div>

                    <div class="tab-pane fade " id="v-pills-careerform" role="tabpanel"
                        aria-labelledby="v-pills-careerform-tab">
                        <Careerform />
                    </div>


                    <div class="tab-pane fade  " id="v-pills-FAQ" role="tabpanel"
                        aria-labelledby="v-pills-FAQ-tab" >

                        <CreateFaq />
                    </div>



                </div>
            </div>


            {/* ===================================MODAL-STARTS==================================== */}




            <div class="modal fade" id="edit-modal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="edit-modalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">CMS</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div className='text-start my-2'>
                                <label for="exampleFormControlTextarea1" class="form-label ">Title</label>
                                <input class="form-control" type="text" aria-label="default input example" />
                            </div>
                            <div className='text-start my-2'>
                                <label for="exampleFormControlTextarea1" class="form-label ">Link Title</label>
                                <input class="form-control" type="text" aria-label="default input example" />
                            </div>
                            <div className='text-start my-2'>
                                <label for="exampleFormControlTextarea1" class="form-label ">Link</label>
                                <input class="form-control" type="text" aria-label="default input example" />
                            </div>
                            <div className='text-start my-2'>
                                <label for="exampleFormControlTextarea1" class="form-label ">Icon</label>
                                <input class="form-control" type="text" aria-label="default input example" />
                            </div>
                            <div className='text-start my-2'>
                                <p>(OR)</p>
                            </div>
                            <div class="mb-3">
                                <input class="form-control" type="file" id="formFile" />
                            </div>
                            <div className='text-start my-2'>
                                <label for="exampleFormControlTextarea1" class="form-label ">Indentify</label>
                                <input class="form-control" type="text" aria-label="default input example" />
                            </div>
                            <div className='text-start my-2'>
                                <label for="exampleFormControlTextarea1" class="form-label ">Description</label>
                                <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                            </div>

                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn " data-bs-dismiss="modal">Cancel</button>
                            <button type="button" class="btn btn-primary">Edit Cms</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default Dashboard;