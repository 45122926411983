import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import bg from "./bg/signin.svg";
import bgimg from "./bg/backimg.jpg";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useState, forwardRef } from "react";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import MuiAlert from "@mui/material/Alert";
import Slide from "@mui/material/Slide";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios"
import configration from "../../src/config/config";
import isEmpty from "is-empty";
import { ToastContainer, toast } from 'react-toastify';
import FormControl from '@mui/material/FormControl';

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

const boxstyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "75%",
  height: "70%",
  bgcolor: "background.paper",
  boxShadow: 24,
};

const center = {
  position: "relative",
  top: "50%",
  left: "30%",
};

export default function ForgotPassword() {
  const token = useParams()
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState("")
  const [error, setError] = useState({})
  const [isloader, setIsloader] = useState(false);
  const vertical = "top";
  const horizontal = "right";
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    setOpen(true);
    event.preventDefault();
    new FormData(event.currentTarget);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  function TransitionLeft(props) {
    return <Slide {...props} direction="left" />;
  }

  async function handleReset(event) {
    event.preventDefault()

    try {
      const emailvalidate = {}

      if (isEmpty(email)) {
        emailvalidate.email = "email is required"
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
        emailvalidate.email = "Invalid email address"
      }

      setError(emailvalidate)

      if (Object.keys(emailvalidate).length === 0) {
        setIsloader(true)
        const response = await axios.post(`${configration.localhostBackend}forget-pass`, { email })
        // console.log("response=====", response);
        const userToken = response.data.userToken
        console.log("res--", response.data.userToken)
        if (userToken) {
          localStorage.setItem("setToken", response.data.userToken);
          toast.success("Email Send Successfully");
          setEmail("")
          setIsloader(false)
        } else {
          toast.warn('Invalid Email');
          setIsloader(false)
        }
      }

    } catch (error) {
      toast.error("Unable to Reset your Mail")
      setIsloader(false)
    }
  }

  return (
    <div className="forget-pw">
      {/* <ToastContainer /> */}
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        TransitionComponent={TransitionLeft}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          Failed! Enter correct username and password.
        </Alert>
      </Snackbar>
      <div
        style={{
          backgroundImage: `url(${bgimg})`,
          backgroundSize: "cover",
          height: "100vh",
          color: "#f5f5f5",
        }}
      >
        <Box sx={boxstyle}>
          <Grid container>
            <Grid item xs={12} sm={12} lg={6} style={{ background: "white" }}>
              <Box
                style={{
                  backgroundImage: `url(${bg})`,
                  backgroundSize: "cover",
                  marginTop: "40px",
                  marginLeft: "15px",
                  marginRight: "15px",
                  height: "63vh",
                  color: "#f5f5f5",
                }}
              ></Box>
            </Grid>
            <Grid item xs={12} sm={12} lg={6}>
              <Box
                style={{
                  backgroundSize: "cover",
                  height: "70vh",
                  minHeight: "500px",
                  backgroundColor: "#3b33d5",
                }}
              >
                <ThemeProvider theme={darkTheme}>
                  <Container>
                    <Box height={35} />
                    <Box sx={center}>
                      <Avatar
                        sx={{ ml: "65px", mb: "4px", bgcolor: "#ffffff" }}
                      >
                        <LockOutlinedIcon />
                      </Avatar>
                      <Typography component="h1" variant="h5" style={{ textAlign: "left" }}>
                        Forgot Password
                      </Typography>
                    </Box>
                    <Box
                      component="form"
                      noValidate
                      onSubmit={handleSubmit}
                      sx={{ mt: 2 }}
                    >
                      <Grid container spacing={1}>
                        <Grid item xs={12} sx={{ ml: "3em", mr: "3em" }}>
                          <FormControl sx={{ mt: 1, width: "100%" }} variant="outlined">
                            <TextField className="email-bg"
                              required
                              fullWidth
                              id="email"
                              label="Email"
                              value={email}
                              onChange={(e) => {
                                setEmail(e.target.value)
                                setError((preverr) => ({ ...preverr, email: "" }))
                              }}
                              name="email"
                              autoComplete="email"
                              error={error.email}
                            />
                            {error.email && <span className="required-css">{error.email}</span>}
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sx={{ ml: "5em", mr: "5em" }}>
                          {isloader ? (
                            <Button
                              type="submit"
                              variant="contained"
                              fullWidth="true"
                              size="large"
                              sx={{
                                mt: "15px",
                                mr: "20px",
                                borderRadius: 28,
                                color: "#ffffff",
                                minWidth: "170px",
                                backgroundColor: "#FF9A01",
                              }}
                            >
                              <div class="spinner-border" role="status">
                                <span class="sr-only" ></span>
                              </div>
                            </Button>
                          ) : (
                            <Button
                              type="submit"
                              onClick={handleReset}
                              variant="contained"
                              fullWidth="true"
                              size="large"
                              sx={{
                                mt: "15px",
                                mr: "20px",
                                borderRadius: 28,
                                color: "#ffffff",
                                minWidth: "170px",
                                backgroundColor: "#FF9A01",
                              }}
                            >
                              Send Reset Link
                            </Button>
                          )}
                        </Grid>
                        <Grid item xs={12} sx={{ ml: "3em", mr: "3em" }}>
                          <Stack direction="row" spacing={2}>
                            <Typography
                              variant="body1"
                              component="span"
                              style={{ marginTop: "10px" }}
                            >
                              Login to your Account.
                              <span
                                style={{ color: "#beb4fb", cursor: "pointer" }}
                                onClick={() => {
                                  navigate("/");
                                }}
                              >
                                {" "}Sign In
                              </span>
                            </Typography>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Box>
                  </Container>
                </ThemeProvider>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </div>
    </div>
  );
}
