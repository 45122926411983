import React, { useEffect, useState } from 'react';
import axios from 'axios';
import config from '../config/config';
import isEmpty from 'is-empty';
import Web3 from 'web3';
import ToastApi from '../config/toastApi';
import DemoCorePlanAbi from './ABI/DemoCorePlan.json';
import DUSDtokenAbi from './ABI/DUSDtokenAbi.json';
import Window_Ethereum_Config_Api from '../config/windowEthereumConfig';
import { toast } from 'react-toastify';
import { WhistlistWaitData, userRegister, ConfirmExternal, ConfirmImmediateExternal } from "./api/authapis"
import { FemaleSharp, Flag } from '@mui/icons-material';
import ReactPaginate from 'react-paginate';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function Whitelistdata() {

    const [whitelist, setWhitelist] = useState([])
    const [connectedWallet, setConnectedWallet] = useState(null)
    const [useraddress, setUseraddress] = useState("")
    const [dchainid, setDchainid] = useState(null);
    const [username, setUsername] = useState("");
    const [isloading, setIsloading] = useState(false)
    const [closebtn, setClosebtn] = useState(false)
    const toastCheck = React.useRef(null);
    const [show, setShow] = useState(false);

    const [overviewTansactionPage, setoverviewTansactionPage] = useState(0);

    const TransactionPerPage = config.TRANSACTION_INDEX_PAGE_NUM

    const numberOfTansactionDetails = overviewTansactionPage * TransactionPerPage;

    const totalPages = Math.ceil(whitelist.length / TransactionPerPage);

    const changePage = ({ selected }) => {

        setoverviewTansactionPage(selected);
    };

    const startIndex = overviewTansactionPage * TransactionPerPage;


    var web3js = '';

    if (window.ethereum) {
        if (connectedWallet != null && dchainid != config.DCXCHAIN_HEX_ID) {
            web3js = new Web3(new Web3.providers.HttpProvider(config.YOUR_NODE_RPC_URL));
        } else if (connectedWallet == null) {
            web3js = new Web3(new Web3.providers.HttpProvider(config.YOUR_NODE_RPC_URL));
        } else {
            web3js = new Web3(window.ethereum);
        }
        // web3js = new Web3(window.ethereum);
        // web3js = new Web3(new Web3.providers.HttpProvider(config.YOUR_NODE_RPC_URL));
    } else {
        web3js = new Web3(new Web3.providers.HttpProvider(config.YOUR_NODE_RPC_URL));
    }

    const DemoCorePlanContract = new web3js.eth.Contract(DemoCorePlanAbi, config.DEMO_CORE_PLAN_CONTRACT_ADDRESS);
    const DUSD_Contract_address = new web3js.eth.Contract(DUSDtokenAbi, config.DUSD_TOKEN_ADDRESS);

    const Onclose = () => {
        setShow(false)
    }

    const addCustomNetwork = async () => {
        try {
            await window.ethereum.request({
                method: Window_Ethereum_Config_Api.wallet_switchEthereumChain,
                params: [{ chainId: config.DCXCHAIN_HEX_ID }]
            });
            toast.success(ToastApi.AddNetworkSuccessToast);
        } catch (switchError) {
            if (switchError.code === 4902) {
                try {
                    await window.ethereum.request({
                        method: Window_Ethereum_Config_Api.wallet_addEthereumChain,
                        params: [
                            {
                                chainId: config.DCXCHAIN_HEX_ID,
                                chainName: config.DCX_NETWORK_NAME,
                                nativeCurrency: {
                                    name: config.DCX_NETWORK_NAME,
                                    symbol: config.DCX_SYMBOL,
                                    decimals: config.DCX_DECIMAL,
                                },
                                rpcUrls: [config.DCXRPC_URL],
                                blockExplorerUrls: [config.DCX_BLOCKEXPLORER],
                            },
                        ],
                    });
                    toast.success(ToastApi.AddNetworkSuccessToast);
                } catch (addError) {
                    console.log("error", addError);
                }
            }
        }
    };

    const checkConnectedWallet = async () => {
        try {
            if (window.ethereum) {
                const accounts = await window.ethereum.request({ method: Window_Ethereum_Config_Api.eth_accounts });
                let checkSumMetamaskAddress = web3js.utils.toChecksumAddress(accounts?.[0]);

                setConnectedWallet(checkSumMetamaskAddress || null);

                const chainId = await window.ethereum.request({ method: Window_Ethereum_Config_Api.eth_chainId });
                setDchainid(chainId)

            }
            else {
                console.log("Metamask Not install");
            }

        } catch (error) {
            console.error('Error checking connected wallet:', error);
        }
    };

    const connectWallet = async () => {
        try {
            if (window.ethereum) {
                const chainId = await window.ethereum.request({ method: Window_Ethereum_Config_Api.eth_chainId });

                if (chainId !== config.DCXCHAIN_HEX_ID) {
                    await addCustomNetwork();
                } else {
                    await window.ethereum.request({ method: Window_Ethereum_Config_Api.eth_requestAccounts });
                    checkConnectedWallet();
                }
            }
            else {
                toast.warn(ToastApi.PleaseInstallMetamsk)
                setTimeout(() => {
                    handleInstallMetaMask('https://metamask.io/download.html')
                }, 1000);
            }
        } catch (error) {
            toast.error(ToastApi.WalletConnectionError);
        }
    };

    const handleInstallMetaMask = async (url) => {
        const newWindow = window.open(url, '_blank');
        if (newWindow) {
            newWindow.focus()
        }
    };

    const switchNetwork = async () => {
        try {
            // Switch network
            await window.ethereum.request({
                method: Window_Ethereum_Config_Api.wallet_switchEthereumChain,
                params: [{ chainId: config.DCXCHAIN_HEX_ID }] // Replace '0x1' with the chain ID you want to switch to
            });
        } catch (error) {

        }
    }

    const InfluencerDetails = async () => {
        try {
            const Response = await WhistlistWaitData();

            if (Response.status === true) {
                const PushWhitels = []

                const Whitelist = Response.data
                Whitelist.forEach(element => {
                    const Objectvalue = {}

                    Objectvalue['_id'] = element._id
                    Objectvalue['name'] = element.userName
                    Objectvalue['address'] = element.userAddress
                    Objectvalue['status'] = element.Status
                    Objectvalue['Id'] = element.InfluencerId
                    Objectvalue['action'] = element.Actions
                    Objectvalue['date'] = element.date

                    PushWhitels.push(Objectvalue)

                });
                setWhitelist(PushWhitels)
            }else{
                setWhitelist([])
            }

        } catch (error) {
            console.log("error", error);
        }
    }

    useEffect(() => {
        InfluencerDetails()
    }, [])

    useEffect(() => {



        const eventAccountWatch = async () => {
            if (window.ethereum) {
                const accounts = await window.ethereum.request({ method: Window_Ethereum_Config_Api.eth_accounts });
                // console.log('accounts', accounts.length);
                if (accounts.length === 1) {
                    let checkSumMetamaskAddress = web3js.utils.toChecksumAddress(accounts[0]);
                    setConnectedWallet(checkSumMetamaskAddress);

                    const chainId = await window.ethereum.request({ method: Window_Ethereum_Config_Api.eth_chainId });
                    setDchainid(chainId)

                }
                // else if (accounts.length > 1) {
                //     if (!toast.isActive(toastCheck.current)) {
                //         if (toastCheck.current === null) {
                //             toastCheck.current = toast.warning(ToastApi.ConnectAnyOneAccount)
                //         }
                //     }
                // }
            }

            // else if (accounts.length == 0) {
            //     toast.warning(ToastApi.ConnectYourWallet);
            // }
        }
        eventAccountWatch();
    }, [])


    const sliceAddress = (address, startLength = 6, endLength = 6) => {
        try {
            if (!address) return '';

            const prefix = address.slice(0, startLength);
            const suffix = address.slice(-endLength);

            return `${prefix}...${suffix}`;
        } catch (error) {

        }
    };

    async function AddressGet(address, id) {
        try {

            const userid = id

            const Reponse = await WhistlistWaitData();

            const ArrayData = Reponse.data

            ArrayData.forEach(element => {

                if (element._id === userid) {
                    const useradd = element.userAddress
                    const name = element.userName
                    setUseraddress(useradd)
                    setUsername(name)
                } else {
                    console.log("id not found");
                }
            });



        } catch (error) {
            console.log("AddressGet", error);
        }
    }

    const handlesubmit = async () => {
        try {
            const chainId = await window.ethereum.request({ method: Window_Ethereum_Config_Api.eth_chainId });
            const WhiteUserAddress = useraddress;

            if (chainId !== config.DCXCHAIN_HEX_ID) {
                return switchNetwork()
            }
            if (!connectedWallet) {
                // Show a toast when the wallet is not connected
                toast.error(ToastApi.walletConnectText);
                return;
            }

            // if (connectedWallet !== config.ADMIN_WALLET_ADDRESS) {
            //     toast.warn(`Please conncet with address ${sliceAddress(config.ADMIN_WALLET_ADDRESS)}`);
            //     return;
            // }

            if (connectedWallet) {
                const WalletBalanceAmt=await DUSD_Contract_address.methods.balanceOf(connectedWallet).call();
                const balDusd= await web3js.utils.fromWei(WalletBalanceAmt,"ether")
        
                if (Number(balDusd) < 15) {
                  toast.warn(`insufficient DUSD balance`)
                  return;
                } 
              }

            const referralId = 1
            setIsloading(true)
            setClosebtn(true)
            // Convert the value to a number
            // const referralId = parseInt(getReferralIdToRegister, 10);


            let checkSumMetamaskAddress = web3js.utils.toChecksumAddress(WhiteUserAddress);

 


            // Check if the conversion is successful and it's a valid number
            if (!isNaN(referralId)) {
                try {


                    // Check if the user is already registered
                    const checkUserRegisteredExists = await DemoCorePlanContract.methods.isUserExists(WhiteUserAddress).call();

                    if (checkUserRegisteredExists) {
                        setIsloading(false)
                        setClosebtn(false)
                        return toast.error(ToastApi.UserAlreadyRegistered);
                    } else {
                        // Fetch the upline referral address
                        const getUplineReferralAddressByID = await DemoCorePlanContract.methods.idToAddress(referralId).call();


                        const registrationToastErrorMessage = config.REGISTRATION_ZERO_ADDRESS;

                        if (getUplineReferralAddressByID === registrationToastErrorMessage) {
                            toast.warning(ToastApi.ReferralIdNotFound);
                            setIsloading(false)
                            setClosebtn(false)
                        } else {
                            const gasPriceWei = web3js.utils.toWei('20', 'gwei');
                            await approvedusd(connectedWallet).then(async (res) => {
                                if (res == true) {
                                    const alreadyApprovedContract = await DemoCorePlanContract.methods.registrationFor(checkSumMetamaskAddress, getUplineReferralAddressByID)
                                        .send({
                                            from: connectedWallet,
                                            gasPrice: gasPriceWei,
                                            nonce: await web3js.eth.getTransactionCount(connectedWallet),
                                        });
                                    // console.log('alreadyApprovedContract----', alreadyApprovedContract);
                                    // console.log('alreadyApprovedContract.events', alreadyApprovedContract.events)

                                    if (alreadyApprovedContract.transactionHash && alreadyApprovedContract.status == 1n) {

                                        const txHash = alreadyApprovedContract.transactionHash;
                                        const status = alreadyApprovedContract.status;

                                        const returnContractValues = alreadyApprovedContract.events.Registration.returnValues;
                                        const registeredAmount = alreadyApprovedContract.events.rewardpercentage.returnValues;
                                        const xCoreAmount = alreadyApprovedContract.events.xcoreuserincome.returnValues;
                                        const xcoresplit = alreadyApprovedContract.events.xcoresplit.returnValues;
                                        const requestBody = {
                                            txHash: alreadyApprovedContract.transactionHash,
                                            status: alreadyApprovedContract.status,
                                            returnContractValues: alreadyApprovedContract.events.Registration.returnValues,
                                            registeredAmount: alreadyApprovedContract.events.rewardpercentage.returnValues,
                                            xCoreAmount: alreadyApprovedContract.events.xcoreuserincome.returnValues,
                                            xcoresplit: alreadyApprovedContract.events.xcoresplit.returnValues,
                                            alreadyApprovedContract: alreadyApprovedContract // If you need the entire object
                                        };

                                        const registerDataSave = await userRegister(requestBody);


                                        const Resquestexternalline = {
                                            returnContractValues: alreadyApprovedContract.events.Registration.returnValues,
                                            Cycleline: "External",
                                            userName: username
                                        }


                                        const ExternalDataSave = await ConfirmImmediateExternal(Resquestexternalline)


                                        if (registerDataSave && ExternalDataSave) {
                                            toast.success(ToastApi.SuccessfullyRegistered);
                                            setIsloading(false)
                                            setClosebtn(false)
                                            Onclose()
                                            InfluencerDetails()
                                            setTimeout(() => {
                                                window.location.href = "/dashboard";
                                                setIsloading(false)
                                            }, 1500);
                                        }
                                        else {
                                            setIsloading(false)
                                            setClosebtn(false)
                                        }

                                    } else {
                                        toast.error(ToastApi.RegistrationErrorToast);
                                        // setIsloading(false)
                                    }

                                }
                                else {
                                    toast.error(ToastApi.SomeThingWentWorng);
                                    setIsloading(false)
                                    setClosebtn(false)
                                }

                            })


                        }
                    }
                } catch (error) {
                    console.error('Error:', error);
                    toast.error(ToastApi.SomeThingWentWorng);
                    setClosebtn(false)
                    setIsloading(false)
                }
            } else {
                console.error('Invalid number');
                setClosebtn(false)
                setIsloading(false)
            }
        } catch (error) {
            // Handle the outer catch block, if needed
            console.log("error", error);
            setClosebtn(false)
            setIsloading(false)
        }
    }


    const approvedusd = async (address) => {
        try {
            const allowance = await DUSD_Contract_address.methods.allowance(connectedWallet, config.DEMO_CORE_PLAN_CONTRACT_ADDRESS).call();
            const registerationFeeAmount = await web3js.utils.toWei("15", "ether");
            const approvedAmountString = allowance.toString();
            let allowancefromwei = await web3js.utils.fromWei(allowance, 'ether')

            const gasPriceWei = web3js.utils.toWei('20', 'gwei');

            if (allowancefromwei >= registerationFeeAmount) {
                return true
            } else {
                const ApproveResult = await DUSD_Contract_address.methods.approve(config.DEMO_CORE_PLAN_CONTRACT_ADDRESS, registerationFeeAmount)
                    .send({
                        from: connectedWallet,
                        gasPrice: gasPriceWei,
                    });

                if (ApproveResult) {
                    return true
                } else {
                    return false
                }

            }
        } catch (error) {
            console.log('error', error)

        }
    }


    function formatDate(dateObject) {
        const year = dateObject.getUTCFullYear();
        const month = String(dateObject.getUTCMonth() + 1).padStart(2, '0');
        const day = String(dateObject.getUTCDate()).padStart(2, '0');
        const hours = String(dateObject.getUTCHours()).padStart(2, '0');
        const minutes = String(dateObject.getUTCMinutes()).padStart(2, '0');
        const seconds = String(dateObject.getUTCSeconds()).padStart(2, '0');
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }

    const customSort = (a, b) => {
        if (a.status === 'Active' && b.status !== 'Active') {
            return 1; // a goes after b
        }
        if (a.status !== 'Active' && b.status === 'Active') {
            return -1; // a goes before b
        }
        // If both have the same status or neither is 'active', sort by date
        return new Date(a.date) - new Date(b.date);
    }
    return (
        <div className='listdata'>
            <div className='list-data'>
                <div className='container'>
                    <div className='row'>
                        <div className='col'>
                            <h1 className='py-5' style={{ color: "rgb(8, 29, 68)" }}>Whitelist Data</h1>

                            <div className='d-flex align-items-center justify-content-between py-4'>
                                {/* <div className=''>
                                        <form action="/search" method="GET">
                                            <input type="text" id="query" name="query" placeholder='Type...' required/>
                                                <button type="submit" className='search py-1 px-2 m-2'>Search</button>
                                        </form>
                                </div> */}
                                <div className=''>
                                    {connectedWallet
                                        ? (<button class="nav-link wallet-address" > <span>Wallet Address</span> :  {sliceAddress(connectedWallet)}</button>)
                                        : (<button class="nav-link" onClick={connectWallet}><i
                                            class="bi bi-box-arrow-in-right" ></i> <span class="dashboard-text-1 p-2 wallet"> Connect Wallet</span>
                                        </button>)}

                                </div>
                            </div>

                            <table class="data-table">

                                <tr>
                                    <th>User name</th>
                                    <th>User address</th>
                                    <th>Id</th>
                                    <th>Status</th>
                                    <th>Actions</th>
                                    <th>Date</th>
                                </tr>
                                <tbody>
                                    {whitelist && whitelist.length > 0
                                        ? (
                                            whitelist.sort(customSort).slice(numberOfTansactionDetails, numberOfTansactionDetails + TransactionPerPage).map((item, ind) => (
                                                <tr key={ind}>
                                                    <td>{item.name}</td>
                                                    <td>{sliceAddress(item.address)}</td>
                                                    <td><span>{item.Id}</span></td>
                                                    <td><span style={item.status === "In-processing"
                                                        ? { color: "#DAA520", fontSize: "16px", fontWeight: "bold" }
                                                        : { color: "#00B300", fontSize: "16px", fontWeight: "bold" }}>
                                                        {item.status}
                                                    </span></td>
                                                    <td>
                                                        {
                                                            (item.action)
                                                                ? (
                                                                    <span> Joined </span>
                                                                )
                                                                : (<button type="button" class="btn data-table-btn"  onClick={() => {
                                                                    AddressGet(item.address, item._id)
                                                                    setShow(true)

                                                                }}>
                                                                    Immediate Join
                                                                </button>)
                                                        }

                                                    </td>
                                                    <td>{formatDate(new Date(item.date))}</td>
                                                </tr>
                                            ))

                                        )
                                        : (<tr>
                                            <td></td>
                                            <td></td>
                                            <td>Data Not Found</td>
                                            <td></td>
                                            <td></td>
                                        </tr>)}

                                </tbody>
                            </table>
                            <ReactPaginate
                                previousLabel={"Previous"}
                                nextLabel={"Next"}
                                pageCount={totalPages}
                                onPageChange={changePage}
                                containerClassName={"navigationButtons"}
                                previousLinkClassName={"previousButton"}
                                nextLinkClassName={"nextButton"}
                                disabledClassName={"navigationDisabled"}
                                activeClassName={"navigationActive"}
                            />

                            {/* <div class="modal fade" id="Immediate" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                <div class="modal-dialog">
                                    <div class="modal-content">
                                        <div class="modal-header text-center">
                                            <h1 class="modal-title fs-5 ms-auto" id="staticBackdropLabel">Immediate Join</h1>
                                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
                                        </div>
                                        <div class="modal-body">
                                            <h4 style={{ color: "#DAA520" }}>Are you sure!</h4>
                                            <p>Do you want join external line ?</p>
                                        </div>
                                        <div class="modal-footer justify-content-center border-0">
                                            {
                                                isloading
                                                    ? (<button type="button" class="btn data-table-btn" data-bs-dismiss="false"  >
                                                        <div class="spinner-border text-light" role="status">
                                                            <span class="sr-only">Loading...</span>
                                                        </div></button>)
                                                    : (<button type="button" class="btn data-table-btn" data-bs-dismiss="false" onClick={handlesubmit} >Confirm</button>)
                                            }
                                            
                                            <button type="button" class="btn btn-danger" data-bs-dismiss="modal" disabled={closebtn ? true : false}>Close</button>
                                        </div>
                                    </div>
                                </div>
                            </div> */}

                            <Modal className='list-data'
                                show={show}
                                onHide={Onclose}
                                backdrop="static"
                                keyboard={false}
                            >
                                <Modal.Header closeButton className='justify-content-center d-flex flex-column-reverse'>
                                    <Modal.Title className='text-center'>Immediate Join</Modal.Title>
                                </Modal.Header>
                                <Modal.Body className='text-center'>
                                    <h4 style={{ color: "#DAA520" }}>Are you sure!</h4>
                                    <p>Do you want join external line ?</p>
                                </Modal.Body>
                                <Modal.Footer className='d-flex justify-content-center'>
                                    {
                                        isloading
                                            ? (<button type="button" class="btn data-table-btn" data-bs-dismiss="false"  >
                                                <div class="spinner-border text-light" role="status">
                                                    <span class="sr-only">Loading...</span>
                                                </div></button>)
                                            : (<button type="button" class="btn data-table-btn" data-bs-dismiss="false" onClick={handlesubmit} >Confirm</button>)
                                    }
                                    <button type="button" class="btn btn-danger" data-bs-dismiss="modal" onClick={Onclose} disabled={closebtn ? true : false}>Close</button>
                                </Modal.Footer>
                            </Modal>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Whitelistdata