import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { getthecareerdetails, deleteCareer } from "./api/authapis"
import Window_Ethereum_Config_Api from '../config/windowEthereumConfig';
import { toast } from 'react-toastify';
import config from '../config/config';
import axios from 'axios';

function Careerform() {

    const [careerdata, setCareerdata] = useState([]);
    const [getid, setGetid] = useState();
    const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false)
        setGetid('')
    };
    const handleShow = (id) => {
        setShow(true)
        setGetid(id)
    };


    const CareerDetails = async () => {
        try {
            const Response = await getthecareerdetails();

            if (Response.status === true) {
                const PushData = []

                const RequestData = Response.data
                RequestData.forEach(element => {
                    const Objectvalue = {}

                    Objectvalue['id'] = element._id
                    Objectvalue['Email'] = element.Email
                    Objectvalue['Position'] = element.Position
                    Objectvalue['Salary'] = element.Salary
                    Objectvalue['Jobtime'] = element.Jobtime
                    Objectvalue['date'] = element.date

                    PushData.push(Objectvalue)

                });

                setCareerdata(PushData)
            } else {
                // toast.warn("something wrong data reponse")
            }

        } catch (error) {
            console.log("error", error);
        }
    }


    useEffect(() => {
        CareerDetails();
    }, [])


    const HandleDelete = async () => {
        try {      
            const id ={id:getid}
            const response = await axios.post(`${config.localhostBackend}A1/careerdelete`,id);

            if (response.data.status === true) {
                toast.success("UserDetails deleted.")
                setTimeout(() => {
                    window.location.href="/dashboard"
                }, 1500);
                handleClose();
                await CareerDetails();
            } else {
                console.log('something functions error')
            }
        } catch (error) {
            console.log('HandleDelete Error:', error)
        }
    }



    return (
        <div className='careerform'>
            <div className='container'>
                <div className='row'>
                    <div className='col'>
                        <h1 className='py-5' style={{ color: "rgb(8, 29, 68)" }}>Career Form</h1>
                        <table className='career-table'>
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Email</th>
                                    <th>Position</th>
                                    <th>Salary</th>
                                    <th>Job Category</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {careerdata && careerdata.length > 0
                                    ? (careerdata.map((item, ind) => (
                                        <tr key={ind}>
                                            <td>{ind + 1}</td>
                                            <td>{item.Email}</td>
                                            <td>{item.Position}</td>
                                            <td>{item.Salary}</td>
                                            <td>{item.Jobtime}</td>
                                            <td><Button variant="danger" onClick={() => handleShow(item.id)}>
                                                Delete
                                            </Button></td>
                                        </tr>
                                    )))
                                    : (
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td>Data Not found</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    )}

                            </tbody>
                        </table>

                        {/* Delete mmodal */}
                        <Modal
                            show={show}
                            onHide={handleClose}
                            backdrop="static"
                            keyboard={false}
                        >
                            <Modal.Header closeButton className='border-0'>
                            </Modal.Header>
                            <Modal.Body>
                                <h4 className='text-center'>Are you sure do you want to delete ?</h4>
                                <div className='d-flex align-items-centet gap-3 mt-5 justify-content-center'>
                                    <Button variant="secondary" onClick={handleClose}>No</Button>
                                    <Button variant="primary" onClick={HandleDelete}>Yes</Button>
                                </div>
                            </Modal.Body>
                        </Modal>
                        {/* Delete mmodal */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Careerform;