
import { BiLock, BiLockOpen } from 'react-icons/bi';
import { MdEdit } from 'react-icons/md';
import { RiDeleteBin6Fill } from 'react-icons/ri';
import { getAllwithdrawTransactionDetails } from './api/authapis';
import React, { useState, useEffect } from 'react';
import ToastApi from '../config/toastApi';
import ReactPaginate from 'react-paginate';
import config from '../config/config';

function Transactionsection() {
    const [withdrawDetails, setWithdrawDetails] = useState([]);
    const [visibleItems, setVisibleItems] = useState(config.REGISTER_HISTORY_SEE_MORE_LESS);
    const [arrayTxHashValue, setArrayTxHashValue] = useState([]);

    const ShowWithdrawTransaction = async () => {
        try {

            const TransactionResults = await getAllwithdrawTransactionDetails();
            const getTotalWithdrawDetails = TransactionResults.data.data;
            // console.log("getTotalWithdrawDetails===", getTotalWithdrawDetails);
            const pushWithdrawDataInArray = [];

            getTotalWithdrawDetails.forEach(element => {
                const objectValue = {};
                let totalRewardAmount = 0;

                for (let i = 0; i < element.Reward_Amount.length; i++) {
                    const calAmount = element.Reward_Amount[i];
                    totalRewardAmount += parseFloat(calAmount); // Convert to number and add to sum
                }
                objectValue['id'] = element._id;
                objectValue['Tx_Hash'] = element.Tx_Hash;
                objectValue['To_Address'] = element.To_Address;
                objectValue['Rank_Status'] = element.Rank_Status;
                objectValue['Number_Of_Reward_count'] = element.Number_Of_Reward_count;
                objectValue['userId'] = element.userId;
                objectValue['Reward_Amount'] = totalRewardAmount;

                const dateObject = new Date(element.Create_At);
                if (!isNaN(dateObject.getTime())) {
                    // const year = dateObject.getFullYear();
                    // const month = String(dateObject.getMonth() + 1).padStart(2, '0');
                    // const day = String(dateObject.getDate()).padStart(2, '0');
                    // const hours = String(dateObject.getHours()).padStart(2, '0');
                    // const minutes = String(dateObject.getMinutes()).padStart(2, '0');
                    // const seconds = String(dateObject.getSeconds()).padStart(2, '0');
                    const year = dateObject.getUTCFullYear();
                    const month = String(dateObject.getUTCMonth() + 1).padStart(2, '0');
                    const day = String(dateObject.getUTCDate()).padStart(2, '0');
                    const hours = String(dateObject.getUTCHours()).padStart(2, '0');
                    const minutes = String(dateObject.getUTCMinutes()).padStart(2, '0');
                    const seconds = String(dateObject.getUTCSeconds()).padStart(2, '0');
                    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
                    objectValue['formattedDate'] = formattedDate;
                } else {
                    objectValue['formattedDate'] = 'Reward not claimed'; // Indicate reward not claimed
                }

                pushWithdrawDataInArray.push(objectValue);
            });

            setWithdrawDetails(pushWithdrawDataInArray);
        } catch (error) {
            console.log("setWithdrawDetailserror----", error);
        }
    }


    const handleSeeMoreClick = () => {
        // Increase the number of visible items (you can adjust the step as needed)
        setVisibleItems((prevVisibleItems) => prevVisibleItems + config.REGISTER_HISTORY_SEE_MORE_LESS);
    };

    const handleSeeLessClick = () => {
        // Decrease the number of visible items (you can adjust the step as needed)
        setVisibleItems((prevVisibleItems) => Math.max(prevVisibleItems - config.REGISTER_HISTORY_SEE_MORE_LESS, config.REGISTER_HISTORY_SEE_MORE_LESS));
    };

    const sliceAddress = (address, startLength = 6, endLength = 6) => {
        try {
            if (!address) return '';

            const prefix = address.slice(0, startLength);
            const suffix = address.slice(-endLength);
            // console.log('address---', address);
            return `${prefix}...${suffix}`;
        } catch (error) {

        }

    };
    useEffect(() => {
        ShowWithdrawTransaction();
    }, [])

    const [overviewTansactionPage, setoverviewTansactionPage] = useState(0);

    const TransactionPerPage = config.TRANSACTION_INDEX_PAGE_NUM;

    const numberOfTansactionDetails = overviewTansactionPage * TransactionPerPage;

    const totalPages = Math.ceil(withdrawDetails.length / TransactionPerPage);

    const changePage = ({ selected }) => {

        setoverviewTansactionPage(selected);
    };

    const startIndex = overviewTansactionPage * TransactionPerPage;

    const filterTxHash = async (Db_Id) => {
        try {
            const TransactionResults = await getAllwithdrawTransactionDetails();

           console.log('TransactionResults.data.data', TransactionResults.data.data)
            // Check if TransactionResults is an array
            if (Array.isArray(TransactionResults.data.data)) {
                const filteredTransaction = TransactionResults.data.data.find(element => element._id === Db_Id);
                console.log("filteredTransaction---", filteredTransaction);

                const hashArray = [];

                filteredTransaction.Tx_Hash.forEach((hashData, index) => {
                    console.log('hashData---', hashData);
                    const hashValue = {};

                    const dateObject = new Date(filteredTransaction.All_Tansaction_Date[index]);
                    console.log("fiterdate--", filteredTransaction.All_Tansaction_Date);
                    // const year = dateObject.getFullYear();
                    // const month = String(dateObject.getMonth() + 1).padStart(2, '0');
                    // const day = String(dateObject.getDate()).padStart(2, '0');
                    // const hours = String(dateObject.getHours()).padStart(2, '0');
                    // const minutes = String(dateObject.getMinutes()).padStart(2, '0');
                    // const seconds = String(dateObject.getSeconds()).padStart(2, '0');
                    const year = dateObject.getUTCFullYear();
                    const month = String(dateObject.getUTCMonth() + 1).padStart(2, '0');
                    const day = String(dateObject.getUTCDate()).padStart(2, '0');
                    const hours = String(dateObject.getUTCHours()).padStart(2, '0');
                    const minutes = String(dateObject.getUTCMinutes()).padStart(2, '0');
                    const seconds = String(dateObject.getUTCSeconds()).padStart(2, '0');
                    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

                    console.log("formattedDate", formattedDate);
                    // hashValue['tx_hash'] = { hash: hashData, date: filteredTransaction.All_Tansaction_Date[index] };
                    hashValue['tx_hash'] = { hash: hashData, date: formattedDate,rank_status:filteredTransaction.Ranking_Sec[index] };
                    hashArray.push(hashValue);
                });

                setArrayTxHashValue(hashArray)
                console.log("hashArray", hashArray);
                // If you want to return the filtered transaction, you can do so
                return filteredTransaction;
            } else {
                console.error("TransactionResults is not an array");
                // Handle the case where TransactionResults is not an array
            }
        } catch (error) {
            console.error("Error filtering transaction by Db_Id:", error);
            // Handle errors appropriately
        }
    }

console.log('arrayTxHashValue', arrayTxHashValue)

    return (
        <div>
            <div className='container transaction-section'>
                <div className='row'>
                    <div className='col'>
                        <div class="container">
                            <h1 className='pb-5' style={{ color: "rgb(8, 29, 68)" }}>Transaction Section</h1>
                            <table class="trans-table">

                                <tr>
                                    <th>SI.no</th>
                                    <th>To Address</th>
                                    <th>ID</th>
                                    <th>Rank</th>
                                    <th>Reward Amount</th>
                                    <th>Hash</th>
                                    <th>Count of Reward</th>
                                    <th>Last Reward Date</th>
                                </tr>
                                <tbody>
                                    {
                                        withdrawDetails.length == 0 ? (
                                            <>
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td>Record Not Found</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>

                                                </tr>
                                            </>
                                        ) : (
                                            withdrawDetails.length > 0 &&
                                            withdrawDetails.slice(numberOfTansactionDetails, numberOfTansactionDetails + TransactionPerPage)?.map((item, index) => {
                                                const transactionIndex = startIndex + index + 1;
                                                return (
                                                    <tr key={index}>
                                                        <td data-th="Supplier Code">
                                                            {transactionIndex}
                                                        </td>
                                                        <td data-th="Supplier Name">
                                                            {sliceAddress(item.To_Address)}
                                                        </td>

                                                        <td data-th="Invoice Number">
                                                            {item.userId}
                                                        </td>
                                                        <td data-th="Invoice Number">
                                                            {item.Rank_Status}
                                                        </td>
                                                        <td data-th="Invoice Number">
                                                            {Number(item.Reward_Amount).toFixed(2)}
                                                        </td>
                                                        <td>
                                                            {/* <td data-th="Invoice Number">
                                                            <a href={`${config.D_CHAIN_EXPLORER_TXHASH_URL + item.Tx_Hash}`} target='_blank'>
                                                                {item.Tx_Hash}
                                                            </a>
                                                            {/* <!-- Button trigger modal --> */}
                                                            <button type="button" class="btn trans-table-btn" data-bs-toggle="modal" data-bs-target="#exampleModal" value={item.id} onClick={() => { filterTxHash(item.id) }}>
                                                                View Transaction Hash
                                                            </button>


                                                        </td>
                                                        <td data-th="Invoice Number">
                                                            {item.Number_Of_Reward_count}
                                                        </td>
                                                        <td data-th="Invoice Number">
                                                            {item.formattedDate}
                                                        </td>

                                                    </tr>
                                                );
                                            })
                                        )}

                                </tbody>
                            </table>

                            {/* <!-- Modal --> */}
                            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                                    <div class="modal-content">
                                        <div class="modal-header text-center">
                                            <h1 class="modal-title fs-5 ms-auto" id="exampleModalLabel">View Transaction Hash</h1>
                                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div>
                                        <div class="modal-body text-dark">
                                            <table className='hash-table'>
                                                <thead>
                                                    <tr>
                                                        <th>SI.no</th>
                                                        <th>Dx Hash</th>
                                                        <th>Rank</th>
                                                        <th>Date</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {arrayTxHashValue.length === 0 ? (
                                                        <tr>
                                                            <td colSpan="2" class="text-center">No Transactions</td>
                                                        </tr>
                                                    ) : (
                                                        arrayTxHashValue
                                                            .sort((a, b) => new Date(b.tx_hash.date) - new Date(a.tx_hash.date)) // Sort by date
                                                            .map((item, index) => (
                                                                <tr key={index}>
                                                                    <td>{index + 1}</td>
                                                                    <td>
                                                                        <a className="hash-link"href={`${config.D_CHAIN_EXPLORER_TXHASH_URL + item.tx_hash.hash}`} target='_blank'>
                                                                            {sliceAddress(item.tx_hash.hash)}
                                                                        </a>
                                                                    </td>
                                                                    <td>{item.tx_hash.rank_status}</td>
                                                                    <td>{item.tx_hash.date}</td>
                                                                </tr>
                                                            ))
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className='mx-auto py-5'>
                                <ReactPaginate
                                    previousLabel={"Previous"}
                                    nextLabel={"Next"}
                                    pageCount={totalPages}
                                    onPageChange={changePage}
                                    containerClassName={"navigationButtons"}
                                    previousLinkClassName={"previousButton"}
                                    nextLinkClassName={"nextButton"}
                                    disabledClassName={"navigationDisabled"}
                                    activeClassName={"navigationActive"}
                                />
                                {/* <nav aria-label="Page navigation example">
                                    <ul class="pagination justify-content-center">
                                        <li class="page-item"><a class="page-link" href="#">Previous</a></li>
                                        <li class="page-item"><a class="page-link" href="#">1</a></li>
                                        <li class="page-item"><a class="page-link" href="#">2</a></li>
                                        <li class="page-item"><a class="page-link" href="#">3</a></li>
                                        <li class="page-item"><a class="page-link" href="#">Next</a></li>
                                    </ul>
                                </nav> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Transactionsection