import { makeApiRequest } from "../../Axios/axiosService";

export const getAllRewardUsers = async () => {
  try {
    // console.log('params for register--', params);
    let params = {
      url: `A1/getAllRewardUsers`,
      method: "GET",
    }
    const response = await makeApiRequest(params);
    return {
      data: response
    }

  } catch (error) {
    console.log('registerUser error', error);
    return {
      status: false,
      message: "Error on server"
    }
  }
}

export const buyNewLevelApprovalStatus = async (data) => {
  try {
    // console.log('params for register--', params);
    let params = {
      url: "v1/buyNewLevelApprovalStatus",
      method: "POST",
      data: data
    }
    const response = await makeApiRequest(params);
    return {
      data: response,
      status: response.status,
      message: response.message
    }

  } catch (error) {
    console.log('buyNewLevelOfMLMId error', error);
    return {
      status: false,
      message: "Error on server"
    }
  }
}


export const withdrawTransactionDetails = async (data) => {
  try {
    // console.log('params for register--', params);
    let params = {
      url: `A1/withdrawTransactionDetails`,
      method: "POST",
      data: data
    }
    const response = await makeApiRequest(params);
    return {
      data: response,
      status: response.status,
      message: response.message
    }

  } catch (error) {
    console.log('registerUser error', error);
    return {
      status: false,
      message: "Error on server"
    }
  }
}


export const getAllwithdrawTransactionDetails = async () => {
  try {
    // console.log('params for register--', params);
    let params = {
      url: `A1/getAllwithdrawTransactionDetails`,
      method: "GET",
    }
    const response = await makeApiRequest(params);
    return {
      data: response
    }

  } catch (error) {
    console.log('registerUser error', error);
    return {
      status: false,
      message: "Error on server"
    }
  }
}

export const getdetailsadmin = async () => {
  try {
    let params = {
      url: `checkisadmin`,
      method: "GET"
    }
    const response = await makeApiRequest(params);

    return {
      data: response
    }
  } catch (error) {
    console.log("checkedadmindatils", error);
    return {
      status: false,
      message: "Error on server"
    }
  }
}

export const getwhitelsdetails = async () => {
  try {
    let params = {
      url: `v1/Influencerlist`,
      method: "GET"
    }
    const response = await makeApiRequest(params);

    return {
      status: response.status,
      message: response.message,
      data: response.userDetails
    }
  } catch (error) {
    console.log("checkedadmindatils", error);
    return {
      status: false,
      message: "Error on server"
    }
  }
}



export const UserWhitelistprocess = async (data) => {
  try {
    let params = {
      url: `A1/UserWhitelist`,
      method: "POST",
      data: data
    }
    const response = await makeApiRequest(params);

    return {
      status: response.status,
      message: response.message,
      data: response.WaitingUser
    }
  } catch (error) {
    console.log("checkedadmindatils", error);
    return {
      status: false,
      message: "Error on server"
    }
  }
}


export const WhistlistWaitData = async () => {
  try {
    let params = {
      url: `A1/WhistlistData`,
      method: "GET"
    }
    const response = await makeApiRequest(params);

    return {
      status: response.status,
      message: response.message,
      data: response.whitelist
    }
  } catch (error) {
    console.log("checkedadmindatils", error);
    return {
      status: false,
      message: "Error on server"
    }
  }
}


export const userRegister = async (data) => {
  try {
    // console.log('params for register--', params);
    console.log('data', data)
    let params = {
      url: "v1/registerApi",
      method: "POST",
      data: data
    }
    const response = await makeApiRequest(params);
    return {
      data: response,
      status: response.status,
      message: response.message
    }

  } catch (error) {
    console.log('registerUser error', error);
    return {
      status: false,
      message: "Error on server"
    }
  }
}


export const ConfirmExternal = async (data) => {
  try {
    // console.log('params for register--', params);

    let params = {
      url: "A1/ConfirmExternal",
      method: "POST",
      data: data
    }
    const response = await makeApiRequest(params);
    return {
      data: response.Externaladduser,
      status: response.status,
      message: response.message
    }

  } catch (error) {
    console.log('registerUser error', error);
    return {
      status: false,
      message: "Error on server"
    }
  }
}


export const ConfirmImmediateExternal = async (data) => {
  try {
    // console.log('params for register--', params);

    let params = {
      url: "A1/ConfirmImmediateExternal",
      method: "POST",
      data: data
    }
    const response = await makeApiRequest(params);
    return {
      data: response.UserDetails,
      status: response.status,
      message: response.message
    }

  } catch (error) {
    console.log('registerUser error', error);
    return {
      status: false,
      message: "Error on server"
    }
  }
}

export const Getuserexternal = async () => {
  try {
    let params = {
      url: `A1/GetuserExternal`,
      method: "GET"
    }
    const response = await makeApiRequest(params);

    return {
      status: response.status,
      message: response.message,
      data: response.UserExternal
    }
  } catch (error) {
    console.log("checkedadmindatils", error);
    return {
      status: false,
      message: "Error on server"
    }
  }
}


export const InfluencerDeletecall = async (influencerId) => {
  try {
    // console.log('params for register--', params);
    let params = {
      url: `A1/InfluencerDeletecall/${influencerId}`,
      method: "POST",
    }
    const response = await makeApiRequest(params);
    // console.log('response----response--', response);
    return {
      status: response.status,
      message: response.message,
    }

  } catch (error) {
    console.log('registerUser error', error);
    return {
      status: false,
      message: "Error on server"
    }
  }
}

export const getthecareerdetails = async () => {
  try {
    let params = {
      url: `A1/getthecareers`,
      method: "GET"
    }
    const response = await makeApiRequest(params);

    return {
      data: response.data,
      status:response.status,
      message:response.message
    }
  } catch (error) {
    console.log("getthecareerdetails", error);
    return {
      status: false,
      message: "Error on server"
    }
  }
}


export const deleteCareer = async (id) => {
  try {

    let params = {
      url: "A1/careerdelete",
      method: "POST",
      data: id
    }
    
    const response = await makeApiRequest(params);
    return {
      data: response.data,
      status: response.status,
      message: response.message
    }

  } catch (error) {
    console.log('registerUser error', error);
    return {
      status: false,
      message: "Error on server"
    }
  }
}




